import { createAction, createSlice } from '@reduxjs/toolkit';

const initState = {
  list: [],
  pnr: null,
};

const FlightTicketSlice = createSlice({
  name: 'FlightTicket',
  initialState: initState,
  reducers: {
    getAllFlightTickets(state, { payload }) {
      state.list = payload;
    },
    getAllPnr(state, { payload }) {
      state.pnr = payload;
    },
  },
});

export const { getAllFlightTickets, getAllPnr } = FlightTicketSlice.actions;

export const getAllFlightTicketsRequest = createAction(
  'User/getAllFlightTickets_request',
);

export const getAllPNRRequest = createAction('User/getAllPNR_request');

export default FlightTicketSlice;
