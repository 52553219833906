import React from 'react';
import { BellFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { tint } from 'polished';

const BellIcon = styled(BellFilled)`
  font-size: 2.5rem;
  color: ${props => tint(0.1, props.theme.primary)};
`;

export default function TopbarNotification() {
  return <BellIcon rotate={15} />;
}
