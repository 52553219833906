import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import getValidateMessages from 'lib/helpers/getValidationMessages';
import { isScreenSize } from 'styles';
import { ReactComponent as WhiteAddIcon } from './assets/white-add-icon.svg';
import SinglePassengerForm from './SinglePassengerForm';
import { getMappedPassengerForApi } from './helper';
import { updatePassengersRequest } from './reducer';

function PassengerDetailsForm({ passengerList, totalPassenger, isUpdatable }) {
  const [form] = Form.useForm();
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const bookingId = useSelector(state => state.User.booking.selected?.id);

  const onFinish = ({ passengers }) => {
    setSubmitLoading(true);
    dispatch(
      updatePassengersRequest({
        bookingId,
        formData: { passengers: getMappedPassengerForApi(passengers) },
        successTxt: messages['Travelers Information updated successfully!'],
        stopSubmitLoading: () => setSubmitLoading(false),
      }),
    );
  };

  useEffect(() => {
    const allPassengers = passengerList.map(passenger => {
      return {
        ...passenger?.user,
        ...passenger,
        is_separate: passenger?.is_adult,
        date_of_birth: passenger?.date_of_birth
          ? moment(passenger?.date_of_birth)
          : null,
      };
    });
    form.setFieldsValue({
      passengers: allPassengers,
    });
  }, [passengerList, form, dispatch]);

  return (
    <Form
      name="passenger_details"
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      validateMessages={getValidateMessages(messages)}
      form={form}
      className="width-full"
    >
      <Form.List name="passengers">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map(field => (
                <SinglePassengerForm
                  field={field}
                  isRepeatedForm
                  remove={remove}
                  key={field.key}
                  apiPassenger={passengerList[field.key]}
                  form={form}
                  isUpdatable={isUpdatable}
                />
              ))}
              {fields.length < totalPassenger && (
                <Row className="mt-2" justify="center">
                  <Col>
                    <Form.Item>
                      <Button
                        style={{
                          height: 'auto',
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                        icon={<WhiteAddIcon />}
                        size="large"
                        onClick={() => {
                          add({ is_separate: true });
                        }}
                      >
                        <span className="ml-3">
                          {messages['Add Additional Passenger']}
                        </span>
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
          );
        }}
      </Form.List>
      <Row className="mt-4 mb-3" type="flex" justify="center">
        <Button
          size={isScreenSize('sm') ? null : 'large'}
          style={{ margin: '0 8px' }}
          onClick={() => goBack()}
        >
          <FormattedMessage id="Cancel" />
        </Button>
        <Button
          size={isScreenSize('sm') ? null : 'large'}
          type="primary"
          htmlType="submit"
          loading={isSubmitLoading}
        >
          <FormattedMessage id="Save" />
        </Button>
      </Row>
    </Form>
  );
}

PassengerDetailsForm.propTypes = {
  passengerList: PropTypes.array,
  totalPassenger: PropTypes.number,
  isUpdatable: PropTypes.bool,
};

export default PassengerDetailsForm;
