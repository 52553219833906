import { takeLatest, put, call } from 'redux-saga/effects';
import { Auth, PassReset } from 'api';
import { message } from 'antd';
import {
  updateUserInfo,
  changeSelectedBooking,
  getAllBooking,
} from 'features/Topbar';
import { resetPayment } from 'features/Payments/reducer';
import {
  activateUserRequest,
  login,
  loginRequest,
  passResetEmailRequest,
  passResetRequest,
} from './reducer';

export function* loginSaga({
  payload: { formData, successText, setIsSubmitting, history },
}) {
  const { data } = yield call(Auth.login, formData);
  setIsSubmitting(false);
  if (data?.key) {
    message.success(successText);
    yield put(login(data.key));
    yield put(resetPayment());
    yield put(getAllBooking(null));
    yield put(changeSelectedBooking(null));
  }
  if (data?.user) {
    yield put(updateUserInfo(data.user));
  }
  if (data?.key) history.push('/dashboard');
}

export function* activateUserSaga({
  payload: { userData, setIsActive, setHasError },
}) {
  const { data } = yield call(Auth.activate, userData);
  if (data) {
    setIsActive(true);
  } else {
    setHasError(true);
  }
}

export function* passResetEmailSaga({
  payload: { formData, setIsEmailSent, setIsSpinnerShowing },
}) {
  const { data } = yield call(PassReset.sendActivationEmail, formData);
  if (data) {
    setIsEmailSent(true);
  }
  setIsSpinnerShowing(false);
}

export function* passResetSaga({
  payload: { formData, successTxt, setIsPasswordReset },
}) {
  const { data } = yield call(PassReset.resetPassword, formData);
  if (data) {
    message.success(successTxt);
    setIsPasswordReset(true);
  }
}

export default function* watchAuthActions() {
  yield takeLatest(loginRequest.toString(), loginSaga);
  yield takeLatest(activateUserRequest.toString(), activateUserSaga);
  yield takeLatest(passResetEmailRequest.toString(), passResetEmailSaga);
  yield takeLatest(passResetRequest.toString(), passResetSaga);
}
