import React from 'react';
import { Menu, Dropdown, Avatar, Button, Row, Col } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { UserOutlined } from '@ant-design/icons';
import { logout } from 'features/UserAuth';
import { isScreenSize } from 'styles';

const { Item } = Menu;

const MenuItem = styled(Item)`
  padding: 1rem 10rem 1rem 3rem;
`;
export default function TopbarUser(props) {
  const dispatch = useDispatch();
  const UserInfo = useSelector(state => state.User.info);
  const menu = (
    <Menu>
      <p className="px-4 py-3">
        <FormattedMessage id="Hi" />
        {`, ${UserInfo?.first_name} ${UserInfo?.last_name}`}
      </p>
      <MenuItem key="2" onClick={() => dispatch(logout())}>
        <FormattedMessage id="Logout" />
      </MenuItem>
    </Menu>
  );

  if (isScreenSize('sm')) {
    return (
      <Row>
        <Col span={24}>
          <p className="py-3 px-5 text-left">
            <FormattedMessage id="Hi" />
            {`, ${UserInfo?.first_name} ${UserInfo?.last_name}`}
          </p>
        </Col>

        <Button
          type="link"
          className="mb-2 ml-3"
          onClick={() => dispatch(logout())}
        >
          <FormattedMessage id="Logout" />
        </Button>
      </Row>
    );
  }

  return (
    <Dropdown {...props} placement="bottomRight" overlay={menu} trigger="click">
      <Avatar size={40} src={UserInfo?.avatar} icon={<UserOutlined />} />
    </Dropdown>
  );
}
