const theme = {
  primary: '#0F55BE',
  secondary: '#FFC167',
  bg: '#F3F7FD',
  shadow: `0px 4px 15px rgba(35, 123, 176, 0.06)`,
  lightShadow: '0px 4px 15px rgba(35, 123, 176, 0.03)',
  dropShadow: '0px 6px 20px rgba(121, 121, 121, 0.1)',
  lightGrey: '#BEC9D7',
  grey: '#6D7986',
  success: '#19D637',
  warning: '#FEA836',
  info: '#0485FA',
  error: '#FC4549',
  black: 'rgba(0, 0, 0, 0.85)',
  white: '#fff',
  support: '#FFEFEE',
  moresailingBlue: '#377CB3',
};

export default theme;
