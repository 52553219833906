import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Progress, Space } from 'antd';
import {
  LayoutContentWrapper,
  LayoutContent,
  Title,
  SimpleTable,
  Paragraph,
  isScreenSize,
  Text,
} from 'styles';
import styled, { createGlobalStyle, useTheme } from 'styled-components';
import { tint } from 'polished';
import { useSelector, useDispatch } from 'react-redux';
import { getTransactionListRequest } from 'features/Topbar';
import dayjs from 'dayjs';
import {
  DownCircleOutlined,
  DownloadOutlined,
  UpCircleOutlined,
} from '@ant-design/icons';
import { downloadInvoiceUrl } from 'api/endpoints';
import _ from 'lodash';

const { Column } = SimpleTable;

const ResponsiveTable = createGlobalStyle`
  .expandedRow {
      margin-left: 7.5rem !important;
  }
  @media (max-width: 768px) {
    .ant-table thead .ant-table-cell { 
      font-size: 1.45rem !important; 
    }
    .ant-table tbody .ant-table-cell { 
      font-size: 1.2rem !important;  
    }
    .ant-table tbody td { 
      padding: 1rem 1.5rem !important; 
      white-space:nowrap
    }
    .ant-table thead th {
      padding: 1rem 1.5rem 
      !important; 
      white-space:nowrap
    }
    .expandedRow {
      margin-left: 2.7rem !important;
    }
  }
`;

const PayementStatusGrid = styled.div`
  display: grid;
  grid-template-areas: 'paid pending total';
  grid-gap: 1.5rem;
  margin-bottom: 2rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.8rem;
    grid-template-areas:
      'paid'
      'pending'
      'total';
    && > *::after {
      content: '';
    }
    grid-gap: 0.25rem;
  }
  & > *::after {
    content: '|';
    font-size: 1.5rem;
    padding-left: 1.2rem;
    color: ${props => props.theme.lightGrey};
  }
  & > *:nth-child(3)::after {
    content: '';
  }
`;

function PaymentStatus() {
  const theme = useTheme();
  const booking = useSelector(state => state.User.booking.selected);
  const transactionList = useSelector(state => state.User.transactionList);
  const [paymentPortion, setPaymentPortion] = useState({
    paid: 0,
    pending: 0,
  });
  const isPaymentSucessful = useSelector(
    state => state.Payment.isPaymentSucessful,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTransactionListRequest(booking?.id));
  }, [booking, dispatch, isPaymentSucessful]);
  useEffect(() => {
    if (transactionList) {
      let paid = 0;
      let pending = 0;
      transactionList.forEach(transaction => {
        if (
          transaction.is_paid === 'Paid' ||
          transaction.is_paid === 'Debited'
        ) {
          paid += transaction.amount;
        } else {
          pending += transaction.amount;
        }
      });
      setPaymentPortion({
        paid,
        pending,
      });
    }
  }, [transactionList]);

  const cardPaymentDetails = record => {
    return (
      <Row className="expandedRow" gutter={30}>
        <Col
          span={24}
          style={{
            color: theme.grey,
            fontWeight: 400,
            marginBottom: '1rem',
          }}
        >
          <b>
            <FormattedMessage id="Transaction ID" />
            {`: `}
          </b>
          {record.transaction_id}
        </Col>
        <Col>
          <b>
            <FormattedMessage id="Given name" />
            {`: `}
          </b>
          {record.shipping_address?.given_name}
          <br />
          <b>
            <FormattedMessage id="Family name" />
            {`: `}
          </b>
          {record.shipping_address?.family_name}
        </Col>
        <Col>
          <b>
            <FormattedMessage id="Phone" />
            {`: `}
          </b>
          {record.shipping_address?.phone}
          <br />
          <b>
            <FormattedMessage id="Email" />
            {`: `}
          </b>
          {record.shipping_address?.email}
        </Col>
        <Col>
          <b>
            <FormattedMessage id="Street address" />
            {`: `}
          </b>
          {record.shipping_address?.street_address}
          <br />
          <b>
            <FormattedMessage id="City" />
            {`: `}
          </b>
          {record.shipping_address?.city}
        </Col>
      </Row>
    );
  };

  const invoicePaymentDetails = record => {
    return (
      <Row style={{ margin: '0rem 7rem' }} gutter={30}>
        <Col>
          <b>
            <FormattedMessage id="Name" />
            {`: `}
          </b>
          {record.shipping_address?.name}
          <br />
          <b>
            <FormattedMessage id="Street address" />
            {`: `}
          </b>
          {record.shipping_address?.street_address}
        </Col>
        <Col>
          <b>
            <FormattedMessage id="City" />
            {`: `}
          </b>
          {record.shipping_address?.city}
          <br />
          <b>
            <FormattedMessage id="Post code" />
            {`: `}
          </b>
          {record.shipping_address?.postal_code}
        </Col>
        <Col>
          <b>
            <FormattedMessage id="Bank giro Number" />
            {`: `}
          </b>
          {record.shipping_address?.bankgiro_number}
          <br />
          <b>
            <FormattedMessage id="Created at" />
            {`: `}
          </b>
          {record.shipping_address?.create_date}
        </Col>
      </Row>
    );
  };
  return (
    <LayoutContentWrapper>
      <LayoutContent className="shadow">
        <Row>
          <Col span={24}>
            <Title className="mb-4" level={isScreenSize('md') ? 3 : 5}>
              <FormattedMessage id="Payment Status" />
            </Title>
          </Col>
          <Col span={24} align="end">
            <Title
              level={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <PayementStatusGrid>
                <div>
                  <Paragraph
                    className="mr-3"
                    style={{ display: 'inline-block', fontWeight: 'normal' }}
                  >
                    <FormattedMessage id="Paid" />
                  </Paragraph>
                  <span className="mr-1">
                    {paymentPortion.paid?.toLocaleString('sv-SE')}
                    <sup style={{ fontSize: '1.2rem', top: '-0.8em' }}>
                      {booking?.region.currency}
                    </sup>
                  </span>
                </div>
                <div>
                  <Paragraph
                    className="mr-3"
                    color="secondary"
                    style={{ display: 'inline-block', fontWeight: 'normal' }}
                  >
                    <FormattedMessage id="Pending" />
                  </Paragraph>
                  <span className="mr-1" style={{ color: theme.secondary }}>
                    {paymentPortion.pending?.toLocaleString('sv-SE')}
                    <sup style={{ fontSize: '1.2rem', top: '-0.8em' }}>
                      {booking?.region.currency}
                    </sup>
                  </span>
                </div>
                <div>
                  {' '}
                  <Paragraph
                    className="mr-3"
                    color="grey"
                    style={{ display: 'inline-block', fontWeight: 'normal' }}
                  >
                    <FormattedMessage id="Total" />
                  </Paragraph>
                  <span className="mr-1" style={{ color: theme.grey }}>
                    {`${booking?.total_payable?.toLocaleString('sv-SE')}`}
                    <sup style={{ fontSize: '1.2rem', top: '-0.8em' }}>
                      {booking?.region.currency}
                    </sup>
                  </span>
                </div>
              </PayementStatusGrid>
            </Title>
          </Col>
          <Col span={24}>
            <Progress
              strokeWidth={23}
              strokeColor={{
                '0%': tint(0.4, theme.secondary),
                '50%': tint(0.2, theme.secondary),
                '100%': theme.secondary,
              }}
              percent={booking?.paid_percentage}
              success={{
                percent: (paymentPortion.paid / booking?.total_payable) * 100,
                strokeColor: theme.primary,
              }}
            />
          </Col>
          <Col span={24}>
            <ResponsiveTable />
            <SimpleTable
              className="mt-4"
              dataSource={transactionList}
              pagination={false}
              expandable={{
                expandedRowRender: record =>
                  record.payment_type === 'invoice'
                    ? invoicePaymentDetails(record)
                    : cardPaymentDetails(record),
                rowExpandable: record => !_.isEmpty(record?.shipping_address),
                expandRowByClick: true,
                expandIconColumnIndex: 0,
                // eslint-disable-next-line react/prop-types
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <UpCircleOutlined
                      onClick={e => onExpand(record, e)}
                      style={{ color: theme.primary }}
                    />
                  ) : (
                    // eslint-disable-next-line react/prop-types
                    !_.isEmpty(record?.shipping_address) && (
                      <DownCircleOutlined
                        onClick={e => onExpand(record, e)}
                        style={{ color: theme.lightGrey }}
                      />
                    )
                  ),
              }}
              rowKey="id"
              noShadow
              scroll={{ x: !isScreenSize('lg') ? true : undefined }}
            >
              <Column
                title={<FormattedMessage id="Previous Transactions" />}
                dataIndex="card_invoice_number"
                render={(__, row) => (
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <FormattedMessage id="Payment from" />
                    {` ${row?.payment_method} ${
                      row?.card_invoice_number ? row?.card_invoice_number : ''
                    }`}
                    {row?.payment_method === 'invoice' && (
                      <a href={`${downloadInvoiceUrl}${row?.id}/download/`}>
                        <DownloadOutlined className="ml-2" />
                      </a>
                    )}
                    {row?.payment_method === 'invoice' && (
                      <Row className="mt-2">
                        <Col>
                          <Text tint={0.4}>
                            <Space>
                              OCR:
                              {row?.ocr_number
                                .toString()
                                .replace(/\d{4}(?=.)/g, '$& ')}
                            </Space>
                          </Text>
                        </Col>
                      </Row>
                    )}
                  </div>
                )}
              />
              <Column
                title={<FormattedMessage id="Paid by" />}
                dataIndex="paid_by"
              />
              <Column
                title={<FormattedMessage id="Date" />}
                dataIndex="created_at"
                render={data => dayjs(data).format('hh:mm MMM DD, YYYY')}
              />

              <Column
                title={<FormattedMessage id="Status" />}
                dataIndex="is_paid"
                render={(___, row) => (
                  <div
                    style={{
                      textAlign: 'center',
                      color:
                        // eslint-disable-next-line no-nested-ternary
                        row.is_paid === 'Paid'
                          ? theme.primary
                          : row.is_paid === 'Pending'
                          ? theme.secondary
                          : theme.error,
                    }}
                  >
                    <FormattedMessage id={row.is_paid} />
                  </div>
                )}
              />

              <Column
                title={<FormattedMessage id="Amount" />}
                dataIndex="amount"
                render={(___, row) => (
                  <span>{row?.amount?.toLocaleString('sv-SE')}</span>
                )}
                align="right"
              />
            </SimpleTable>
          </Col>
        </Row>
      </LayoutContent>
    </LayoutContentWrapper>
  );
}

export default PaymentStatus;
