import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Switch,
  DatePicker,
  Select,
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Checkbox, Title, Text, isScreenSize } from 'styles';
import { CloseCircleOutlined, MailOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { emailBookingInfoRequest } from './reducer';
import { BlueContainer } from './styles';

const { TextArea } = Input;
const { Option } = Select;
function SinglePassengerForm({
  field = {},
  remove,
  form,
  isUpdatable,
  apiPassenger = {},
}) {
  const bookingId = useSelector(state => state.User.booking.selected?.id);
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const currentField = form.getFieldsValue()?.passengers[field.name];

  const isOld = currentField?.id || false;
  const isPrimaryPassenger = field?.key === 0;
  const [isSeparate, setIsSeparate] = useState(currentField?.is_separate);
  const [hasAllergie, setHasAllergie] = useState(currentField?.allergie);

  function handleIsChildChange(val) {
    setIsSeparate(val);
  }

  function handleEmailBookingInfo() {
    const formData = {
      passenger_id: currentField?.id,
      booking_id: bookingId,
    };

    dispatch(
      emailBookingInfoRequest({
        formData,
        successTxt: messages['Email successfully sent!'],
      }),
    );
  }

  useEffect(() => {
    setIsSeparate(currentField?.is_separate);
    setHasAllergie(currentField?.allergie);
  }, [currentField]);

  return (
    <Row gutter={50} className="my-2">
      <Col span={24}>
        <BlueContainer>
          <div className="title">
            <Title level={4}>
              {`${messages['Passenger']} - ${field?.name + 1}`}
            </Title>
          </div>
          <div className="buttons">
            {field?.name > 0 && !isOld && (
              <Col>
                <Button
                  onClick={() => {
                    remove(field.name);
                  }}
                  type="dashed"
                  className="background-none remove-btn"
                  icon={<CloseCircleOutlined />}
                  size={isScreenSize('lg') ? 'large' : null}
                >
                  {messages['Remove']}
                </Button>
              </Col>
            )}
            {isOld && (
              <Button
                onClick={handleEmailBookingInfo}
                type="ghost"
                size={isScreenSize('lg') ? 'large' : null}
                icon={<MailOutlined />}
              >
                {messages['Email Booking Info']}
              </Button>
            )}
          </div>
          <div className="seperate-login">
            {field?.name > 0 && (
              <Row
                align="middle"
                gutter={10}
                style={{
                  flexWrap: 'nowrap',
                }}
              >
                <Col>
                  <Text style={{ marginRight: '5px' }}>
                    {messages['This passenger want a separate log in']}
                  </Text>
                </Col>
                <Col>
                  <Form.Item
                    name={[field?.name, 'is_separate']}
                    fieldKey={[field?.fieldKey, 'is_separate']}
                    style={{ marginBottom: 0 }}
                  >
                    <Switch
                      checked={isSeparate}
                      checkedChildren={<FormattedMessage id="Yes" />}
                      unCheckedChildren={<FormattedMessage id="No" />}
                      onChange={handleIsChildChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </div>
        </BlueContainer>
      </Col>
      <Col xxl={{ span: 8 }} xl={{ span: 12 }}>
        <Form.Item name={[field?.name, 'name_title']} label={messages['Title']}>
          <Select size="large" placeholder={messages['Title']} allowClear>
            <Option value="ms">Ms</Option>
            <Option value="mrs">Mrs</Option>
            <Option value="mr">Mr</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col xxl={{ span: 8 }} md={{ span: 12 }} span={24}>
        <Form.Item
          name={[field?.name, 'first_name']}
          fieldKey={[field?.fieldKey, 'first_name']}
          label={messages['First Name']}
          rules={[{ required: isPrimaryPassenger }]}
        >
          <Input
            disabled={!!apiPassenger?.user?.first_name || isPrimaryPassenger}
            size="large"
            placeholder={messages['First Name']}
          />
        </Form.Item>
      </Col>
      <Col xxl={{ span: 8 }} md={{ span: 12 }} span={24}>
        <Form.Item
          label={messages['Last Name']}
          name={[field?.name, 'last_name']}
          fieldKey={[field?.fieldKey, 'last_name']}
          rules={[{ required: isPrimaryPassenger }]}
        >
          <Input
            disabled={!!apiPassenger?.user?.last_name || isPrimaryPassenger}
            size="large"
            placeholder={messages['Last Name']}
          />
        </Form.Item>
      </Col>
      {isSeparate && (
        <Col xxl={{ span: 8 }} md={{ span: 12 }} span={24}>
          <Form.Item
            label={messages['Email']}
            name={[field?.name, 'email']}
            fieldKey={[field?.fieldKey, 'last']}
            rules={[{ required: isPrimaryPassenger, type: 'email' }]}
          >
            <Input
              disabled={
                (!!apiPassenger?.user?.email && !isUpdatable) ||
                isPrimaryPassenger
              }
              size="large"
              placeholder={messages['Email']}
            />
          </Form.Item>
        </Col>
      )}
      <Col xxl={{ span: 8 }} md={{ span: 12 }} span={20}>
        <Form.Item
          label={`${messages['Date of Birth']}`}
          name={[field?.name, 'date_of_birth']}
          hasFeedback
          rules={[{ required: isPrimaryPassenger }]}
        >
          <DatePicker
            disabled={!!apiPassenger?.date_of_birth}
            size="large"
            style={{ width: '100%' }}
            placeholder="YYYY-MM-DD"
            format={['YYYY-MM-DD', 'YYYYMMDD']}
          />
        </Form.Item>
      </Col>
      <Col xxl={{ span: 8 }} md={{ span: 12 }} span={20}>
        <Form.Item
          label={messages['Telephone Number']}
          name={[field?.name, 'telephone']}
          hasFeedback
        >
          <Input
            size="large"
            disabled={!!apiPassenger?.telephone && !isUpdatable}
            placeholder={messages['Telephone Number']}
          />
        </Form.Item>
      </Col>
      {/* <Col xxl={{ span: 8 }} md={{ span: 12 }}>
        <Form.Item
          label={`${messages['Social security number']} (ÅÅÅÅMMDDXXXX)`}
          name={[field?.name, 'person_number']}
          hasFeedback
        >
          <InputNumber
            className="mt-1"
            style={{ width: '100%' }}
            size="large"
            placeholder="ÅÅÅÅMMDDXXXX"
          />
        </Form.Item>
      </Col> */}
      <Col xxl={{ span: 8 }} md={{ span: 12 }} span={20}>
        <Form.Item
          label={messages['Passport Number']}
          name={[field?.name, 'passport_number']}
          hasFeedback
        >
          <Input
            disabled={!!currentField?.passport_number && !isUpdatable}
            size="large"
            placeholder={messages['Passport Number']}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name={[field?.name, 'allergie']}
          valuePropName="checked"
          labelCol={{ span: 24 }}
        >
          <Checkbox
            disabled={!isUpdatable && !!isOld}
            onChange={val => setHasAllergie(val)}
            className="mt-2"
          >
            {messages['Does the traveler have food allergies?']}
          </Checkbox>
        </Form.Item>
      </Col>
      {hasAllergie && (
        <Col xxl={{ span: 8 }} md={{ span: 12 }} span={20}>
          <Form.Item
            label={messages['Allergie Description']}
            name={[field?.name, 'allergy_description']}
          >
            <TextArea
              autoSize={{ minRows: 4, maxRows: 4 }}
              size="large"
              placeholder={messages['Allergie Description']}
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
}

SinglePassengerForm.propTypes = {
  field: PropTypes.object,
  apiPassenger: PropTypes.object,
  remove: PropTypes.func,
  form: PropTypes.object,
  isUpdatable: PropTypes.bool,
};

export default SinglePassengerForm;
