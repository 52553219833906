import React from 'react';
import { Button, Col, Divider, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  LayoutContent,
  LayoutContentWrapper,
  Paragraph,
  Text,
  Title,
} from 'styles';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { PRIVATE_ROUTE } from 'router';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function DashboardBookingTotal({ showBtn = true }) {
  const booking = useSelector(state => state.User.booking.selected);

  return (
    <LayoutContentWrapper>
      <LayoutContent>
        <Row>
          <Title shade={0.1} level={4}>
            <FormattedMessage id="Total Price" />
          </Title>
          <Divider />
          <Col span={24}>
            <Row justify="space-between">
              <Paragraph shade={0.1}>
                {` ${
                  booking?.booking_kind === 'tour'
                    ? `${booking?.total_passenger} x`
                    : ''
                }
                ${booking?.tour}`}
              </Paragraph>
              <Paragraph shade={0.1}>
                {booking?.price_break_down?.tour?.price?.toLocaleString(
                  'sv-SE',
                )}
              </Paragraph>
            </Row>
            {(
              booking?.price_break_down?.supplements.filter(
                elem => elem?.is_calculate !== false,
              ) || []
            ).map(supple => (
              <Row
                key={supple.title}
                justify="space-between"
                style={{ flexWrap: 'nowrap' }}
              >
                <Col className="pr-4">
                  <Paragraph shade={0.1}>
                    {` ${supple?.quantity ? `${supple?.quantity} x` : ''}  ${
                      supple?.title
                    }`}
                  </Paragraph>
                </Col>
                <Col>
                  <Paragraph shade={0.1}>
                    {supple?.price?.toLocaleString('sv-SE')}
                  </Paragraph>
                </Col>
              </Row>
            ))}
            {booking?.price_break_down?.discount?.discount > 0 && (
              <Row justify="space-between">
                <Paragraph shade={0.1}>
                  <FormattedMessage id="Discount" />
                </Paragraph>
                <Paragraph shade={0.1}>
                  {`-${booking.price_break_down.discount.discount?.toLocaleString(
                    'sv-SE',
                  )}`}
                </Paragraph>
              </Row>
            )}
            {(
              booking?.price_break_down?.supplements.filter(
                elem => elem?.is_calculate === false,
              ) || []
            ).map(supple => (
              <Row
                key={supple.title}
                justify="space-between"
                style={{ flexWrap: 'nowrap' }}
              >
                <Col className="pr-4">
                  <Paragraph style={{ marginBottom: 0 }}>
                    {` ${supple?.quantity ? `${supple?.quantity} x` : ''}  ${
                      supple?.title
                    }`}
                  </Paragraph>
                  <Text color="secondary">
                    (
                    <FormattedMessage id="Not included in total - Payable at base" />
                    )
                  </Text>
                </Col>
                <Col>
                  <Paragraph>
                    {supple?.price?.toLocaleString('sv-SE')}
                  </Paragraph>
                </Col>
              </Row>
            ))}

            <Divider />
            <Row className="mb-2" justify="space-between">
              <Title tint={0.3} level={4}>
                <FormattedMessage id="Total" />
              </Title>
              <Title tint={0.3} style={{ margin: 0 }} level={4}>
                {booking?.total_payable?.toLocaleString('sv-SE')}
              </Title>
            </Row>
          </Col>
          {showBtn && (
            <Col align="middle" className="mt-4" span={24}>
              <Link
                to={`/${PRIVATE_ROUTE.DASHBOARD}/${PRIVATE_ROUTE.PAYMENTS}`}
              >
                <Button type="link">
                  <span>
                    <FormattedMessage id="Make a payment" />
                  </span>
                  <RightOutlined />
                </Button>
              </Link>
            </Col>
          )}
        </Row>
      </LayoutContent>
    </LayoutContentWrapper>
  );
}

DashboardBookingTotal.propTypes = {
  showBtn: PropTypes.bool,
};

export default DashboardBookingTotal;
