import { createGlobalStyle } from 'styled-components';
import { tint, shade } from 'polished';

const AppStyles = createGlobalStyle`

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
  }

  html {
    font-size: 62.5%;

    body {
      font-family: 'Cera Pro', sans-serif;
      line-height: 155%;
  
      input, button, select, optgroup, textarea, span, li, a, button {
        font-family: 'Cera Pro', sans-serif;

      }

      h1, h2, h3, h4, h5, h6 {
        font-family: 'Cera Pro', sans-serif;
        font-weight: bold;
      }


      h1,
      h1.ant-typography  {
        font-size: 3.4rem;
        margin-bottom: 0;
      }

      h2,
      h2.ant-typography  {
        font-size: 2.8rem;
        margin-bottom: 0;
      }

      h3,
      h3.ant-typography  {
        font-size: 2.4rem;
        margin-bottom: 0;
      }

      h4,
      h4.ant-typography {
        font-size: 1.8rem;
        margin-bottom: 0;
      }
      

      h5,
      .ant-checkbox + span {
        font-size: 1.6rem;
        margin-bottom: 0;
      }

      h6 {
        font-size: 1.4rem;
      }


       /* Buttons  */
       .ant-btn {
        font-weight: 500;
        border-radius: 5px;
        font-size: 1.45rem;
        line-height: 2.4rem;
        padding-left: 2.8rem;
        padding-right: 2.8rem;
        height: 4.5rem;
        
        &-lg {
          font-size: 1.6rem;
          line-height: 2.5rem;
          padding-left: 5rem;
          padding-right: 5rem;
          height: 4.8rem;
        }
      }
    

      .ant-btn:not(.ant-btn-primary):not(.ant-btn-link):not(.ant-btn-ghost):not(.ant-btn-dashed):not(.ant-btn-dangerous)  {
        background-color: ${props => tint(0.9, props.theme.primary)};
        border: 1px solid transparent;
        box-shadow: ${props => props.theme.shadow};

      }

      .ant-btn-ghost {
        color: ${props => tint(0.3, props.theme.primary)};
        /* border-color: ${props => tint(0.3, props.theme.primary)}; */
      }
      .remove-btn {
        color: ${props => tint(0.3, props.theme.error)};
      }

      .ant-input,
      .ant-input-number,
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
      .ant-select-show-search.ant-select-multiple .ant-select-selector,
      .ant-picker-large {
        border-radius: .5rem;
        border: 1px solid ${props => tint(0.9, props.theme.primary)};
        
        /* padding: 4px 0; */
      }

      /* .ant-input-lg,
      .ant-input-number-lg input,
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 4.8rem;
      } */

      .ant-form-item-label > label {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 155%;
        color: ${props => shade(0.1, props.theme.primary)};
      }

      .ant-form-item-label > label.ant-form-item-required::before {
        position: absolute;
        right: -1.5rem;
        color: ${props => props.theme.primary};
        font-size: 1.6rem;
      }

      .ant-select-multiple .ant-select-selection-item {
        background-color: ${props => tint(0.9, props.theme.primary)};
      }

      .ant-form-item-label > label {
        color: ${props => shade(0.1, props.theme.primary)};
      }
    }
  }

  /* Ant Design Overrides */

  /* .ant-form-item-explain {
    text-transform: capitalize;
  } */

  /* Utilities  */

  /* SHADOW */

  .shadow {
    box-shadow: ${props => props.theme.shadow};

    &-light {
      box-shadow: ${props => props.theme.lightShadow};
    }
  }

  /* NProgerss */

    #nprogress .bar {
      background: ${props => props.theme.primary};
    }

    #nprogress .peg {
        box-shadow: 0 0 10px ${props => props.theme.primary}, 0 0 5px ${props =>
  props.theme.primary};
    }

    #nprogress .spinner-icon {
      display: none;
    }

   /* Typography Utils */

   .text {
    &-center {
      text-align: center;
    }

    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }
  }


  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 0;
  }
  
`;

// MEDIA QUERY WITH JS ;)
export const breakpoints = {
  xxs: 320,
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};
export const media = key => {
  return style =>
    `@media (${
      key === 'xxs' || key === 'xs' || key === 'sm' ? 'max-width' : 'min-width'
    }: ${breakpoints[key]}px) { ${style} }`;
};

export const isScreenSize = key => {
  if (key === 'xxs' || key === 'xs' || key === 'sm') {
    return window.innerWidth <= breakpoints[key];
  }

  return window.innerWidth >= breakpoints[key];
};

export default AppStyles;
