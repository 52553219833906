import styled from 'styled-components';
import { Checkbox as AntCheckBox } from 'antd';
import { shade } from 'polished';

export const Checkbox = styled(AntCheckBox)`
  color: ${props => shade(0.1, props.theme.primary)};
  font-weight: 500;

  input[type='checkbox'],
  .ant-checkbox-inner {
    height: 2.4rem;
    width: 2.4rem;
    border-radius: 50%;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => shade(0.1, props.theme.primary)};

    &::after {
      left: 32%;
    }
  }
`;
