import { takeLatest, put, call } from 'redux-saga/effects';
import { Booking } from 'api';
import { getAllBookingRequest, getAllBooking } from './reducer';

export function* getAllBookingSaga() {
  const { data } = yield call(Booking.index);

  if (data) {
    yield put(getAllBooking(data));
  }
}

export default function* watchUserActions() {
  yield takeLatest(getAllBookingRequest.toString(), getAllBookingSaga);
}
