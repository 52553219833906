import { takeLatest, put, call } from 'redux-saga/effects';
import { Booking } from 'api';
import { message } from 'antd';
import {
  getAllPassengers,
  getAllPassengersRequest,
  emailBookingInfoRequest,
  updatePassengersRequest,
} from './reducer';

export function* getAllPassengersSaga({ payload }) {
  const { data } = yield call(Booking.getPassengers, payload);

  if (data) {
    yield put(getAllPassengers(data));
  }
}

export function* updatePassengersSaga({
  payload: { bookingId, formData, successTxt, stopSubmitLoading },
}) {
  const response = yield call(Booking.updatePassengers, bookingId, formData);
  if (response) stopSubmitLoading();
  if (response?.data) {
    message.success(successTxt);
    yield call(getAllPassengersSaga, { payload: bookingId });
  }
}

export function* emailBookingInfoSaga({ payload: { formData, successTxt } }) {
  const { data } = yield call(Booking.emailBookingInfo, formData);

  if (data) {
    message.success(successTxt);
  }
}

export default function* watchPassengerActions() {
  yield takeLatest(getAllPassengersRequest.toString(), getAllPassengersSaga);
  yield takeLatest(updatePassengersRequest.toString(), updatePassengersSaga);
  yield takeLatest(emailBookingInfoRequest.toString(), emailBookingInfoSaga);
}
