import React, { useEffect } from 'react';
import { BookingBreakdown } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import MakePayment from './MakePayment';
import PaymentStatus from './PaymentStatus';
// import OldSystemDisclaimer from './OldSystemDisclaimer';
import BookingFeeDiscliamer from './BookingFeeDiscliamer';
import { resetPayment } from './reducer';

function Payments() {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetPayment());
    };
  }, [dispatch]);
  const selectedBooking = useSelector(state => state.User.booking.selected);

  return (
    <>
      {/* <OldSystemDisclaimer /> */}
      <BookingBreakdown showBtn={false} />
      <BookingFeeDiscliamer />
      {selectedBooking?.due > 0 && <MakePayment />}
      <PaymentStatus />
    </>
  );
}

export default Payments;
