import { createSlice, createAction } from '@reduxjs/toolkit';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

const initState = {
  booking: {
    selected: null,
    list: [],
  },
  info: null,
  transactionList: [],
};

const UserSlice = createSlice({
  name: 'User',
  initialState: initState,
  reducers: {
    changeSelectedBooking(state, { payload }) {
      state.booking.selected = payload;
    },
    getAllBooking(state, { payload }) {
      state.booking.list = payload;
      if (state.booking.selected?.id) {
        const booking = find(payload, {
          id: state.booking.selected.id,
        });
        if (!isEqual(state.booking.selected, booking))
          state.booking.selected = booking;
      }
    },
    updateUserInfo(state, { payload }) {
      state.info = payload;
    },
    getTransactionList(state, { payload }) {
      state.transactionList = payload;
    },
  },
});

export const getAllBookingRequest = createAction('User/getAllBooking_request');
export const getTransactionListRequest = createAction(
  'User/getTransactionList_request',
);

export const {
  changeSelectedBooking,
  getAllBooking,
  getTransactionList,
  updateUserInfo,
} = UserSlice.actions;

export default UserSlice;
