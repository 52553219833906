import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Button, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Paragraph } from 'styles';
import {
  checkKlarnaPaySuccReq,
  getKlarnaPaymentIdRequest,
  updatePaymentMethod,
} from './reducer';

function KlarnaPayment({ amount, prev, next }) {
  const dispatch = useDispatch();
  const ref = useRef();
  const nextRef = useRef(next);
  const selectedBooking = useSelector(state => state.User.booking.selected);
  const isPaymentSucessful = useSelector(
    state => state.Payment.isPaymentSucessful,
  );
  const containerId = 'klarna-complete-checkout';
  const [htmlData, setHtmlData] = useState();
  const siteOriginUrl = window.location.origin;
  const fullSiteUrl = siteOriginUrl + window.location.pathname;

  const url = new URL(window.location.href);
  const orderId = url.searchParams.get('order_id');

  function handlePrevPageChange() {
    dispatch(updatePaymentMethod(null));
    prev();
  }

  useEffect(() => {
    if (selectedBooking && !orderId) {
      const reqData = {
        booking_id: selectedBooking?.id,
        amount,
        terms_url: `${siteOriginUrl}?order_id={checkout.order.id}`,
        checkout_url: `${fullSiteUrl}?order_id={checkout.order.id}`,
        confirmation_url: `${fullSiteUrl}?order_id={checkout.order.id}`,
        push_url: `${siteOriginUrl}?order_id={checkout.order.id}`,
      };

      dispatch(getKlarnaPaymentIdRequest({ reqData, setHtmlData }));
    }
  }, [amount, dispatch, selectedBooking, siteOriginUrl, fullSiteUrl, orderId]);

  useEffect(() => {
    if (orderId && !isPaymentSucessful) {
      dispatch(
        checkKlarnaPaySuccReq({
          orderId,
          bookingId: selectedBooking?.id,
          next: nextRef.current,
        }),
      );
    }
    // eslint-disable-next-line
  }, [orderId, dispatch, nextRef, isPaymentSucessful, selectedBooking?.id]);

  useEffect(() => {
    const checkoutContainer = document.getElementById(containerId);
    ref.current.innerHTML = htmlData;

    if (htmlData && checkoutContainer) {
      checkoutContainer.innerHTML = document
        .getElementById('KCO')
        .value.replace(/\\"/g, '"')
        .replace(/\\n/g, '');
      const scriptsTags = checkoutContainer.getElementsByTagName('script');
      for (let i = 0; i < scriptsTags.length; i += 1) {
        const { parentNode } = scriptsTags[i];
        const newScriptTag = document.createElement('script');
        newScriptTag.type = 'text/javascript';
        newScriptTag.text = scriptsTags[i].text;
        parentNode.removeChild(scriptsTags[i]);
        parentNode.appendChild(newScriptTag);
      }
    }
  }, [htmlData]);

  return (
    <>
      <Paragraph color="warning" className="text-center my-4">
        <FormattedMessage id="paymentWarning" />
      </Paragraph>
      <textarea style={{ display: 'none' }} ref={ref} id="KCO" />
      {orderId ? (
        <Row justify="center" align="middle" className="py-5">
          <Spin size="large" />
        </Row>
      ) : (
        <div id={containerId} />
      )}
      <Row className="mt-3" type="flex" justify="center">
        <Button
          size="large"
          style={{ margin: '0 8px' }}
          onClick={handlePrevPageChange}
          disabled={!!orderId}
        >
          <FormattedMessage id="Previous" />
        </Button>
      </Row>
    </>
  );
}

KlarnaPayment.propTypes = {
  amount: PropTypes.number,
  next: PropTypes.func,
  prev: PropTypes.func,
};

export default KlarnaPayment;
