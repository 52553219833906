import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';
import { Card } from 'antd';
import { Paragraph } from 'styles';

const ResponsiveLayout = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    .icon {
      margin-bottom: 15px;
    }
  }
`;

function FlightDetailsDisclaimer() {
  return (
    <Card>
      <Paragraph color="info" style={{ marginBottom: 0 }}>
        <ResponsiveLayout>
          <InfoCircleOutlined className="mr-3 icon" style={{ fontSize: 20 }} />
          <FormattedMessage id="FLIGHT_DETASILS_DISCLAIMER" />
        </ResponsiveLayout>
      </Paragraph>
    </Card>
  );
}

export default FlightDetailsDisclaimer;
