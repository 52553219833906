import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { resetPayment } from 'features/Payments/reducer';
import { isScreenSize } from 'styles';
import { elipsis } from 'lib/helpers/utility';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';
import { getAllBookingRequest, changeSelectedBooking } from './reducer';

const { Option } = Select;

function UserBookingSelect() {
  const dispatch = useDispatch();
  const bookings = useSelector(state => state.User.booking.list);
  const selectedBooking = useSelector(state => state.User.booking.selected);
  const isPaymentSucessful = useSelector(
    state => state.Payment.isPaymentSucessful,
  );
  const { messages } = useIntl();
  function handleBookingChange(id) {
    const booking = _.find(bookings, {
      id,
    });
    dispatch(resetPayment());
    dispatch(changeSelectedBooking(booking));
  }

  useEffect(() => {
    dispatch(getAllBookingRequest());
  }, [dispatch, isPaymentSucessful]);

  useEffect(() => {
    if (!selectedBooking && bookings)
      dispatch(changeSelectedBooking(bookings[0]));
  }, [bookings, selectedBooking, dispatch]);

  return (
    <Select
      className="ml-3"
      size="large"
      value={selectedBooking?.id}
      placeholder={elipsis(
        messages['Please select'],
        isScreenSize('sm') ? 9 : 16,
      )}
      style={{ maxWidth: isScreenSize('xxs') ? '100px' : 'auto' }}
      onChange={handleBookingChange}
    >
      {bookings &&
        bookings.map(booking => (
          <Option
            key={booking.id}
            value={booking.id}
            style={{
              width: 'auto',

              borderBottom: `solid 1px #BEC9D7`,
            }}
          >
            {!isScreenSize('sm') && (
              <>
                <FormattedMessage id="Booking" />
                {': '}
              </>
            )}
            {booking.reference}
            <br />
            <span
              style={{
                fontSize: '12px',
                color: '#6D7986',
              }}
            >
              {!isScreenSize('sm') && (
                <>
                  <FormattedMessage id="Departure" />
                </>
              )}
              {isScreenSize('sm') && (
                <>
                  <CalendarOutlined />
                </>
              )}

              {` ${moment(booking.departure_date_time).format('DD MMM YY')}`}
            </span>
          </Option>
        ))}
    </Select>
  );
}

export default UserBookingSelect;
