import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { tint } from 'polished';

const Header = styled.div`
  background-color: ${props => (props.bg ? props.bg : '#fff')};
  padding: 2rem 4rem;
  box-shadow: ${props => props.theme.lightShadow};
`;

const Title = styled.h3`
  color: ${props => tint(0.1, props.theme.primary)};
`;

const SectionHeader = ({ title = '' }) => {
  return (
    <Header>
      <Title>
        <FormattedMessage id={title} />
      </Title>
    </Header>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string,
};

export default SectionHeader;
