import { http } from 'services';
import {
  bookingUrl,
  bookingTransactionsUrl,
  bookingPassengersUrl,
  passengersUpdateUrl,
  emailBookingInfoUrl,
} from './endpoints';

export const Booking = {
  index: () => http.get(bookingUrl),
  getTransactionList: id => http.get(`${bookingTransactionsUrl}?booking=${id}`),
  getPassengers: id => http.get(`${bookingPassengersUrl}?booking_id=${id}`),
  updatePassengers: (id, data) =>
    http.put(`${passengersUpdateUrl}${id}/`, data),
  emailBookingInfo: data => http.post(emailBookingInfoUrl, data),
};
