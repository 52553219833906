import { takeLatest, put, call } from 'redux-saga/effects';
import { Attachment } from 'api';
import { getAllAttachments, getAllAttachmentsRequest } from './reducer';

export function* getAllAttachmentsSaga({ payload }) {
  const { data } = yield call(Attachment.index, payload);
  if (data) {
    yield put(getAllAttachments(data));
  }
}

export default function* watchAttachmentsActions() {
  yield takeLatest(getAllAttachmentsRequest.toString(), getAllAttachmentsSaga);
}
