import { Table as AntTable } from 'antd';
import styled from 'styled-components';
import { tint, shade } from 'polished';

export const Table = styled(AntTable)`
  overflow: hidden;
  margin-top: 0.5rem;

  .ant-table {
    border-radius: 10px;
    overflow: hidden;

    td,
    th {
      border-bottom: 1px solid color ${props => tint(0.9, props.theme.primary)};
    }

    thead {
      background-color: #fff;

      th {
        background-color: #fff;
        font-size: 1.8rem;
        color: ${props => tint(0.4, props.theme.primary)};
        padding: 2.2rem 4rem;
        border: none;
        box-shadow: 0px 4px 15px rgba(35, 123, 176, 0.03);
      }
    }

    tbody {
      td {
        padding: 3.5rem 4rem;
        font-size: 1.6rem;
        color: ${props => shade(0.5, props.theme.primary)};
      }
    }
  }
`;

export const SimpleTable = styled(AntTable)`
  overflow: hidden;
  margin-top: 0.5rem;

  .ant-table {
    border-radius: 5px;
    overflow: hidden;
    /* border: 1px solid ${props => tint(0.9, props.theme.primary)}; */

    td,
    th {
      border-bottom: 1px solid ${props => tint(0.9, props.theme.primary)};
    }

    thead {
      background-color: #fff;

      th {
        background-color: #fff;
        font-size: 1.8rem;
        /* color: ${props => tint(0.4, props.theme.primary)}; */
        color: ${props => props.theme.lightGrey};
        padding: 2.2rem 4rem;
        border: none;
        border-bottom: 1px solid ${props => tint(0.9, props.theme.primary)};
      }
    }

    tbody {
      td {
        padding: 3.5rem 4rem;
        font-size: 1.6rem;
        color: ${props => props.theme.grey};
        font-weight: 500;
      }
    }

    .ant-table-footer {
      background: none;
      padding: 2.2rem 0;
      font-size: 1.6rem;
      color: ${props => shade(0.1, props.theme.primary)};
      font-weight: 500;
    }
  }
`;

export default Table;
