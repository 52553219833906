import config from 'config';

export const apiUrl = `${config.BACKEND_ROOT_URL}/api/v1/`;

export const authUrl = `${apiUrl}rest-auth/`;

export const loginUrl = `${authUrl}login/`;

export const activateUrl = `${apiUrl}activate/`;

export const bookingUrl = `${apiUrl}passenger-bookings/`;

export const ticketsUrl = `${apiUrl}passenger-flight-tickets/`;

export const pnrUrl = `${apiUrl}customer-booking-tickets/`;

export const dibsPaymentUrl = `${apiUrl}dibs-payment-request/`;

export const dibsPaymentSccsUrl = `${apiUrl}dibs-payment-success/`;

export const klarnaPaymentUrl = `${apiUrl}klarna-payment-request/`;

export const trustlyPaymentUrl = `${apiUrl}trustly-payment-request/`;

export const klarnaPaySuccCheckUrl = `${apiUrl}klarna-payment-check-success/`;

export const bookingTransactionsUrl = `${apiUrl}booking-transactions/`;

export const bookingPassengersUrl = `${apiUrl}booking-passengers/`;

export const passengersUpdateUrl = `${apiUrl}customer-update/`;

export const invoiceUrl = `${apiUrl}invoices/`;

export const passResetEmailUrl = `${apiUrl}reset-password-email-verification/`;

export const passResetUrl = `${apiUrl}reset-user-password/`;

export const emailBookingInfoUrl = `${apiUrl}email-booking-info/`;

export const downloadInvoiceUrl = `${apiUrl}invoice/`;

export const bookingAttachment = `${apiUrl}customer-booking-attachments/`;
