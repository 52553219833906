import { tint } from 'polished';
import styled from 'styled-components';

export const BlueContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  background-color: ${props => tint(0.9, props.theme.primary)};
  padding: 1.6rem;
  border-radius: 5px;
  margin-bottom: 3rem;
  width: 100%;

  grid-template-areas: 'a c b';
  grid-template-columns: 1fr 2fr 1fr;
  @media only screen and (max-width: 1200px) {
    grid-template-areas:
      'a b'
      'c b';
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
  }
  @media only screen and (max-width: 600px) {
    grid-template-areas:
      'a'
      'c'
      'b';
    grid-template-columns: auto;
    grid-template-rows: repeat(3, auto);
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-area: a;
  }
  .buttons {
    grid-area: b;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media only screen and (max-width: 600px) {
      justify-content: center;
    }
  }

  .seperate-login {
    grid-area: c;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 1200px) {
      justify-content: flex-start;
    }
    @media only screen and (max-width: 600px) {
      justify-content: center;
    }
  }
`;
