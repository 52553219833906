import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { LayoutContent, LayoutContentWrapper, Paragraph } from 'styles';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const ResponsiveLayout = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    .icon {
      margin-bottom: 15px;
    }
  }
`;

function BookingFeeDiscliamer() {
  const selectedBooking = useSelector(state => state.User.booking.selected);
  return (
    <LayoutContentWrapper>
      <LayoutContent>
        <Paragraph color="info" className="mt-2">
          <ResponsiveLayout>
            <InfoCircleOutlined
              className="mr-3 icon"
              style={{ fontSize: 20 }}
            />
            {selectedBooking.booking_kind === 'tour' && (
              <FormattedMessage id="bookingFeeDisclaimer" />
            )}
            {selectedBooking.booking_kind === 'yacht' && (
              <FormattedMessage id="boatBookingFeeDisclaimer" />
            )}
          </ResponsiveLayout>
        </Paragraph>
      </LayoutContent>
    </LayoutContentWrapper>
  );
}

export default BookingFeeDiscliamer;
