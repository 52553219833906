import { Row } from 'antd';

import styled from 'styled-components';
import { shade, tint } from 'polished';
import { media } from 'styles';

export const PaymentMethodContainer = styled.div`
  ${media('lg')`width: 90rem;`}

  ${media('sm')`width: 100%; padding: 0 1rem;`}

  margin: 4rem auto;
`;

export const FormContainer = styled(Row)`
  padding: 4rem 2rem;
  border-radius: 5px;

  &,
  input,
  textarea {
    background-color: ${props => props.theme.bg};
    color: ${props => shade(0.1, props.theme.primary)};

    &::placeholder {
      color: ${props => tint(0.7, props.theme.primary)};
    }
  }
`;

export const PaymentMethodCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p =>
    p.selected ? tint(0.9, p.theme.primary) : p.theme.bg};
  padding: 1.6rem 3.2rem;
  border-radius: 5px;
  box-shadow: ${p =>
    p.selected ? '0px 4px 30px rgba(23, 59, 115, 0.1)' : 'none'};
  width: 100%;
  height: 100%;
  cursor: pointer;

  .icon-container {
    width: 4.8rem;
    height: 4.8rem;
    background-color: ${p =>
      p.selected ? tint(0.7, p.theme.primary) : p.theme.support};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.6rem;
    ${media('sm')`display: none;`}

    path {
      transition: all 0.3s ease-in;
      fill: ${props =>
        props.selected ? props.theme.primary : props.theme.lightGrey};
    }

    svg {
      ${media('sm')`width: 1rem;`}
    }
  }
  .logo-container {
    width: 12.5rem;
    height: 4.8rem;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      opacity: ${props => (props.selected ? 1 : 0.7)};
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
      transition: all 0.3s ease-in;
    }

    path {
      transition: all 0.3s ease-in;
      fill: ${props =>
        props.selected ? props.theme.primary : props.theme.lightGrey};
    }
  }

  .text-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    div.ant-typography {
      font-weight: 500;
      ${media('sm')`font-size: 1.4rem;`}
    }
    path {
      fill: ${props => props.theme.grey} !important;
    }

    .ant-typography {
      transition: all 0.3s ease-in;
      color: ${p =>
        p.selected ? tint(0.3, p.theme.primary) : p.theme.lightGrey};
      margin-bottom: 0;
      ${media('sm')`font-size: 1.4rem;`}
    }
  }

  &:hover {
    path {
      fill: ${props => props.theme.primary};
    }
    img {
      opacity: 1;
    }
    .ant-typography {
      color: ${p => tint(0.3, p.theme.primary)};
      margin-bottom: 0;
    }
  }
`;
