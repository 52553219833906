import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Spin, Result, Button } from 'antd';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { SectionHeader } from 'components';
import { activateUserRequest } from 'features/UserAuth';
import { CloseCircleOutlined } from '@ant-design/icons';

const PageLayout = styled(Layout)`
  display: flex;
  justify-content: center;
  align-items: center;

  .activate-content {
    width: 60rem;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: ${props => props.theme.lightShadow};
  }
`;

function ActivateUser() {
  const { token, id } = useParams();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    dispatch(
      activateUserRequest({
        userData: {
          token,
          id,
        },
        setIsActive,
        setHasError,
      }),
    );
  }, [dispatch, token, id]);

  return (
    <PageLayout style={{ height: '100vh' }}>
      <div className="activate-content">
        <SectionHeader title="Activate Account" />
        <Row className="p-3" type="flex" justify="center" align="middle">
          <Col>
            <Spin spinning={!isActive && !hasError} size="large" />
          </Col>
          {isActive && (
            <Col>
              <Result
                status="success"
                title={
                  <FormattedMessage id="Successfully Activated Accoount!" />
                }
                subTitle={
                  <FormattedMessage id="Login with the password that was provided on the email" />
                }
                extra={[
                  <Button size="large" type="primary" key="console">
                    <Link to="/signin">
                      <FormattedMessage id="Login" />
                    </Link>
                  </Button>,
                ]}
              />
            </Col>
          )}
          {hasError && (
            <Col span={24} className="mt-2" align="middle">
              <CloseCircleOutlined style={{ fontSize: '5rem' }} />
            </Col>
          )}
          {!isActive && (
            <Col span={24} className="mt-4" align="middle">
              <Button size="large" type="primary" key="console">
                <Link to="/">
                  <FormattedMessage id="Go Back" />
                </Link>
              </Button>
            </Col>
          )}
        </Row>
      </div>
    </PageLayout>
  );
}

export default ActivateUser;
