import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Button, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  getTrustlyPayemntIdRequest,
  updatePaymentMethod,
  updatePaymentSuccess,
} from './reducer';

function TrustlyPayment({ amount, prev, next }) {
  const dispatch = useDispatch();
  const nextRef = useRef(next);
  const selectedBooking = useSelector(state => state.User.booking.selected);
  const isPaymentSucessful = useSelector(
    state => state.Payment.isPaymentSucessful,
  );
  const [checkoutUrl, setCheckoutUrl] = useState();
  const siteOriginUrl = window.location.origin;
  const fullSiteUrl = siteOriginUrl + window.location.pathname;
  const url = new URL(window.location.href);
  const trustlyStatus = url.searchParams.get('trustly_status');

  function handlePrevPageChange() {
    dispatch(updatePaymentMethod(null));
    prev();
  }

  useEffect(() => {
    if (selectedBooking && !trustlyStatus) {
      const reqData = {
        booking_id: selectedBooking?.id,
        amount,
        success_url: `${fullSiteUrl}?trustly_status=complete`,
        fail_url: `${fullSiteUrl}?trustly_status=failed`,
      };

      dispatch(getTrustlyPayemntIdRequest({ reqData, setCheckoutUrl }));
    }
  }, [
    amount,
    dispatch,
    selectedBooking,
    siteOriginUrl,
    fullSiteUrl,
    trustlyStatus,
  ]);

  useEffect(() => {
    if (trustlyStatus && !isPaymentSucessful) {
      dispatch(updatePaymentSuccess(trustlyStatus === 'complete'));
      nextRef.current();
    }
  }, [trustlyStatus, dispatch, isPaymentSucessful]);

  return (
    <>
      {!checkoutUrl ? (
        <Row justify="center" align="middle" className="py-5">
          <Spin size="large" />
        </Row>
      ) : (
        <Row justify="center" align="middle" className="py-5">
          <iframe
            title="Embedded Trustly Checkout"
            width={600}
            height={600}
            frameBorder="0"
            src={checkoutUrl}
          />
        </Row>
      )}
      <Row className="mt-3" type="flex" justify="center">
        <Button
          size="large"
          style={{ margin: '0 8px' }}
          onClick={handlePrevPageChange}
          disabled={!!trustlyStatus}
        >
          <FormattedMessage id="Previous" />
        </Button>
      </Row>
    </>
  );
}

TrustlyPayment.propTypes = {
  amount: PropTypes.number,
  next: PropTypes.func,
  prev: PropTypes.func,
};

export default TrustlyPayment;
