import React from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, Row } from 'antd';
import styled from 'styled-components';
import { lighten } from 'polished';
import { FormattedMessage } from 'react-intl';

const { Panel } = Collapse;

const StyledRow = styled(Row)`
  .title {
    padding-bottom: 0.5rem;
    font-size: 1.6rem;
    border-bottom: 1px solid ${props => lighten(0.4, props.theme.grey)};
  }
  .grey {
    color: ${props => props.theme.grey};
  }
  .blue {
    color: ${props => props.theme.primary};
    font-weight: 600;
  }
`;

const Header = ({ item }) => (
  <StyledRow>
    <Col className="blue">{item?.passenger?.full_name}</Col>
  </StyledRow>
);

const Content = ({ item }) => (
  <StyledRow gutter={[12, 12]}>
    <Col span={24}>
      <Row>
        <Col span={24} className="grey title mb-4">
          <FormattedMessage id="Outbound" />
        </Col>
        <Col span={12} lg={4}>
          <Row gutter={[6, 6]}>
            <Col span={24} className="grey">
              <FormattedMessage id="Date" />
            </Col>
            <Col span={24} className="blue">
              {item?.ticket?.start_date}
            </Col>
          </Row>
        </Col>
        <Col span={12} lg={5}>
          <Row gutter={[6, 6]}>
            <Col span={24} className="grey">
              <FormattedMessage id="Departure" />
            </Col>
            <Col span={24} className="blue">
              {item?.ticket?.outbound_departure_time}
            </Col>
          </Row>
        </Col>
        <Col span={12} lg={5}>
          <Row gutter={[6, 6]}>
            <Col span={24} className="grey">
              <FormattedMessage id="Departure Location" />
            </Col>
            <Col span={24} className="blue">
              {item?.ticket?.departure}
            </Col>
          </Row>
        </Col>
        <Col span={12} lg={5}>
          <Row gutter={[6, 6]}>
            <Col span={24} className="grey">
              <FormattedMessage id="Arrival" />
            </Col>
            <Col span={24} className="blue">
              {item?.ticket?.outbound_arrival_time}
            </Col>
          </Row>
        </Col>
        <Col span={12} lg={5}>
          <Row gutter={[6, 6]}>
            <Col span={24} className="grey">
              <FormattedMessage id="Destination Location" />
            </Col>
            <Col span={24} className="blue">
              {item?.ticket?.destination}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
    <Col span={24}>
      <Row>
        <Col span={24} className="grey mb-4 title">
          <FormattedMessage id="Inbound" />
        </Col>
        <Col span={12} lg={4}>
          <Row gutter={[6, 6]}>
            <Col span={24} className="grey">
              <FormattedMessage id="Date" />
            </Col>
            <Col span={24} className="blue">
              {item?.ticket?.end_date}
            </Col>
          </Row>
        </Col>
        <Col span={12} lg={5}>
          <Row gutter={[6, 6]}>
            <Col span={24} className="grey">
              <FormattedMessage id="Departure" />
            </Col>
            <Col span={24} className="blue">
              {item?.ticket?.inbound_departure_time}
            </Col>
          </Row>
        </Col>
        <Col span={12} lg={5}>
          <Row gutter={[6, 6]}>
            <Col span={24} className="grey">
              <FormattedMessage id="Departure Location" />
            </Col>
            <Col span={24} className="blue">
              {item?.ticket?.destination}
            </Col>
          </Row>
        </Col>
        <Col span={12} lg={5}>
          <Row gutter={[6, 6]}>
            <Col span={24} className="grey">
              <FormattedMessage id="Arrival" />
            </Col>
            <Col span={24} className="blue">
              {item?.ticket?.inbound_arrival_time}
            </Col>
          </Row>
        </Col>
        <Col span={12} lg={5}>
          <Row gutter={[6, 6]}>
            <Col span={24} className="grey">
              <FormattedMessage id="Destination Location" />
            </Col>
            <Col span={24} className="blue">
              {item?.ticket?.departure}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
    <Col span={24}>
      <Row gutter={[6, 6]}>
        <Col className="grey">
          <FormattedMessage id="Note" />
          {': '}
        </Col>
        <Col className="blue">{item?.ticket?.note}</Col>
      </Row>
    </Col>
  </StyledRow>
);

function PNRList({ list = [] }) {
  return (
    <Collapse>
      {list?.map((item, index) => (
        <Panel header={<Header item={item} />} key={index + 1}>
          <Content item={item} />
        </Panel>
      ))}
    </Collapse>
  );
}

PNRList.propTypes = {
  list: PropTypes.array,
};

Header.propTypes = {
  item: PropTypes.any,
};

Content.propTypes = {
  item: PropTypes.any,
};

export default PNRList;
