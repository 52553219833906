import React, { useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Paragraph, Text } from 'styles';
import { useSelector, useDispatch } from 'react-redux';
import { PaymentMethodContainer, PaymentMethodCard } from './styles';
import { ReactComponent as CardIcon } from './assets/card.svg';
// import { ReactComponent as InvoiceIcon } from './assets/invoice.svg';
import TrustlyIcon from './assets/trustly.png';
// import InvoiceForm from './InvoiceForm';
import KlarnaPayment from './KlarnaPayment';
import { updatePaymentMethod } from './reducer';
import TrustlyPayment from './TrsutlyPayment';

function PaymentMethodStep({ ...rest }) {
  const dispatch = useDispatch();
  const currency = useSelector(
    state => state.User.booking.selected?.region?.currency,
  );
  const bookingKind = useSelector(
    state => state.User.booking.selected?.booking_kind,
  );
  const paymentMethod = useSelector(state => state.Payment.paymentMethod);
  const amount = useSelector(state => state.Payment.invoiceForm?.amount);

  const paymentOptions =
    bookingKind === 'tour'
      ? [
          // {
          //   icon: InvoiceIcon,
          //   title: 'Invoice',
          //   subtitle: amount,
          // },
          {
            icon: CardIcon,
            title: 'Card',
          },
        ]
      : [
          {
            icon: CardIcon,
            title: 'Card',
          },
        ];
  useEffect(() => {
    if (currency !== 'SEK') {
      dispatch(updatePaymentMethod('Card'));
    }
  }, [currency, dispatch]);

  return (
    <PaymentMethodContainer>
      <Paragraph className="text-center my-2" level={3}>
        <FormattedMessage id="paymentDisclaimer" />
      </Paragraph>
      {currency === 'SEK' && (
        <>
          <Row justify="center" align="middle" gutter={[20, 20]}>
            <Col
              xs={24}
              sm={12}
              md={8}
              onClick={() => dispatch(updatePaymentMethod('Trustly'))}
            >
              <PaymentMethodCard selected={paymentMethod === 'Trustly'}>
                <div className="logo-container">
                  <img src={TrustlyIcon} alt="trustly logo" width="100%" />
                </div>
              </PaymentMethodCard>
            </Col>
            {paymentOptions.map(payment => (
              <Col
                key={payment.title}
                xs={24}
                sm={12}
                md={8}
                onClick={() => dispatch(updatePaymentMethod(payment.title))}
              >
                <PaymentMethodCard selected={paymentMethod === payment.title}>
                  <div className="icon-container">
                    <payment.icon />
                  </div>

                  <div className="text-container">
                    <Paragraph>
                      <FormattedMessage id={payment.title} />
                    </Paragraph>
                    {payment.subtitle && (
                      <Text>
                        {`${payment.subtitle?.toLocaleString(
                          'sv-SE',
                        )} ${currency}`}
                      </Text>
                    )}
                  </div>
                </PaymentMethodCard>
              </Col>
            ))}
          </Row>
          <Row justify="center" align="middle" gutter={[20, 20]}>
            {paymentMethod !== 'Card' &&
              paymentMethod !== 'Invoice' &&
              paymentMethod !== 'Trustly' && (
                <Button size="large" className="mt-4" onClick={rest.prev}>
                  <FormattedMessage id="Previous" />
                </Button>
              )}
          </Row>
        </>
      )}

      {/* {paymentMethod === 'Invoice' && <InvoiceForm {...rest} />} */}
      {paymentMethod === 'Card' && <KlarnaPayment amount={amount} {...rest} />}
      {paymentMethod === 'Trustly' && (
        <TrustlyPayment amount={amount} {...rest} />
      )}
    </PaymentMethodContainer>
  );
}

PaymentMethodStep.propTypes = {
  selectPaymentForm: PropTypes.object,
};

export default PaymentMethodStep;
