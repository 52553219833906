import moment from 'moment';

export function getMappedPassengerForApi(passengers) {
  return passengers.map(
    ({
      first_name,
      last_name,
      email,
      is_separate,
      allergie,
      additional_info,
      passport_number,
      date_of_birth,
      person_number,
      telephone,
      id,
      allergy_description,
      name_title,
    }) => ({
      ...(id && { id }),
      user: {
        first_name,
        last_name,
        ...(is_separate && { email: email?.toLowerCase() }),
        name_title,
      },
      is_adult: is_separate,
      allergie,
      additional_info,
      date_of_birth: date_of_birth
        ? moment(date_of_birth).format('YYYY-MM-DD')
        : null,
      telephone,
      passport_number,
      person_number,
      allergy_description,
    }),
  );
}
