import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { LayoutContent, Table, Text } from 'styles';
import { Button, Empty, Row } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

const { Column } = Table;

function FlightList({ list = [] }) {
  const { messages } = useIntl();
  if (list.length === 0)
    return (
      <LayoutContent>
        <Row className="my-4 py-4" justify="center">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <Text color="grey">{messages['noFlightTicketDisclaimer']}</Text>
            }
          />
        </Row>
      </LayoutContent>
    );
  return (
    <Table className="shadow" dataSource={list} pagination={false} rowKey="id">
      <Column
        title={<FormattedMessage id="Name" />}
        dataIndex="name"
        render={(name, record) => (
          <a
            style={{ width: '100%', display: 'block' }}
            href={record.file}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>
        )}
      />
      <Column
        title={<FormattedMessage id="Options" />}
        dataIndex="file"
        align="right"
        responsive={['md']}
        render={file => (
          <a href={file} target="_blank" rel="noopener noreferrer">
            <Button shape="circle" icon={<EyeOutlined />}>
              {messages['View']}
            </Button>
          </a>
        )}
      />
    </Table>
  );
}

FlightList.propTypes = {
  list: PropTypes.array,
};

export default FlightList;
