import React, { useState } from 'react';
import { Row, Form, Input, Button } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import getValidateMessages from 'lib/helpers/getValidationMessages';
import { useHistory, Link } from 'react-router-dom';
import { Paragraph } from 'styles';
import { loginRequest } from './reducer';

function UserAuthForm() {
  const { messages } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFinish = formData => {
    setIsSubmitting(true);
    dispatch(
      loginRequest({
        formData,
        successText: `${messages['Successfully logged in!']}`,
        setIsSubmitting,
        history,
      }),
    );
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      validateMessages={getValidateMessages(messages, 'label')}
      labelCol={{ span: 24 }}
    >
      <Form.Item
        className="mb-1"
        name="email"
        rules={[{ required: true }, { type: 'email' }]}
        label={messages.Email}
        hasFeedback
      >
        <Input size="large" placeholder={messages.Email} />
      </Form.Item>

      <Form.Item
        className="mb-1"
        name="password"
        rules={[{ required: true }]}
        hasFeedback
        label={messages.Password}
      >
        <Input.Password size="large" placeholder={messages.Password} />
      </Form.Item>

      <Link to="/forgotpassword" className="isoForgotPass">
        <Paragraph color="lightGrey">
          <FormattedMessage id="Choose new password" />
        </Paragraph>
      </Link>

      <Row className="mt-3 mb-4" type="flex" justify="center">
        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            disabled={isSubmitting}
          >
            {messages.Login}
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
}

export default UserAuthForm;
