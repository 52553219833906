import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = {
  invoiceForm: {
    postcode: null,
    street: null,
    city: null,
    country: null,
    amount: null,
  },
  step: 0,
  invoicePdfUrl: {
    view: null,
    download: null,
  },
  paymentMethod: null,
  isPaymentSucessful: false,
  paymentSuccessInfo: null,
};

const PaymentSlice = createSlice({
  name: 'Payment',
  initialState: initState,
  reducers: {
    updateAmount(state, { payload }) {
      state.invoiceForm = { ...state.invoiceForm, amount: payload };
    },
    updatePaymentStepInvoiceForm(state, { payload }) {
      state.invoiceForm = { ...state.invoiceForm, ...payload };
    },
    updateInvoiceUrl(state, { payload }) {
      state.invoiceUrl = payload;
    },
    updateStep(state, { payload }) {
      state.step = payload;
    },
    updatePaymentMethod(state, { payload }) {
      state.paymentMethod = payload;
    },
    getInvoicePdf(state, { payload }) {
      state.invoicePdfUrl = payload;
    },

    resetPayment(state) {
      state.invoiceForm = initState.invoiceForm;
      state.step = 0;
      state.invoicePdfUrl = {
        view: null,
        download: null,
      };
      state.paymentSuccessInfo = null;
      state.paymentMethod = null;
      state.isPaymentSucessful = false;
    },
    updatePaymentSuccess(state, { payload }) {
      state.isPaymentSucessful = payload;
    },
    getPaymentSuccessInfo(state, { payload }) {
      state.paymentSuccessInfo = payload;
    },
  },
});

export const getDibsPaymentIdRequest = createAction(
  'Payment/getDibsPaymentId_request',
);
export const getKlarnaPaymentIdRequest = createAction(
  'Payment/getKlarnaPaymentIdRequest_request',
);

export const getTrustlyPayemntIdRequest = createAction(
  'Payment/getTrustlyPaymentIdRequest_request',
);

export const checkKlarnaPaySuccReq = createAction(
  'Payment/checkKlarnaPaySuccReq',
);

export const getPaymentIdRequest = createAction('Payment/getPaymentId_request');
export const getInvoicePdfRequest = createAction(
  'Payment/getInvoicePdf_request',
);

export const dibsSuccessPayReq = createAction(
  'Payment/dibsSuccessPayReq_request',
);

export const {
  updateAmount,
  updatePaymentStepInvoiceForm,
  updateStep,
  updatePaymentMethod,
  getInvoicePdf,
  resetPayment,
  updatePaymentSuccess,
  getPaymentSuccessInfo,
} = PaymentSlice.actions;

export default PaymentSlice;
