export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  ACTIVATE: '/activate/:id/:token',
  FORGOT_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/password-reset/:id/:token',
};

export const PRIVATE_ROUTE = {
  DASHBOARD_HOME: '/',
  DASHBOARD: 'dashboard',
  HOME: '',
  DETAILS: 'details',
  FLIGHTS: 'fligts',
  ATTACHMENTS: 'attachments',
  PAYMENTS: 'payments',
  SUCCESS_PAY: 'success-pay',
  TRAVEL_INFORMATION: 'travel-information',
};
