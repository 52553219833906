import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import styled from 'styled-components';
import { Redirect, useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RightOutlined } from '@ant-design/icons';
import { LangSwitch } from 'features/Topbar';
import { Title, Text, media } from 'styles';
import { shade } from 'polished';
import UserAuthForm from './UserAuthForm';
import bgImage from './assets/login-bg.jpg';
import msLogo from '../../assets/images/ms-logo.svg';

const RowWrapper = styled(Row)`
  background: url(${bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;

  .login-content {
    width: 108rem;
    box-shadow: ${props => props.theme.lightShadow};
    background-color: #fff;
    border-radius: 0.6rem;

    ${media('sm')`
        width: 90%;
    `};

    &--left {
      background-color: ${props => props.theme.moresailingBlue};

      .left-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding: 7rem 4rem 4rem 4rem;
      }
      ${media('sm')`
        display: none;
      `};
    }

    &--right {
      padding: 2rem 4rem;

      img {
        width: 90%;
      }
    }

    button.ant-btn.ant-btn-lg {
      color: ${props => shade(0.4, props.theme.primary)};
      background-color: ${props => props.theme.secondary};
      border: 1px solid transparent;
      padding-left: 6rem;
      padding-right: 6rem;
    }
    .new-portal-small-screen {
      border: 1px solid ${props => props.theme.moresailingBlue};
      padding: 1.2rem;
      border-radius: 0.6rem;
      & {
        ${media('sm')`
        display: flex;
        flex-direction: column;
      `};
      }
      display: none;

      width: 100%;
      button {
        color: ${props => props.theme.primary};
      }
      p {
        margin: 0;
        padding-top: 1rem;
      }
    }
  }
`;

function UserAuth() {
  const location = useLocation();
  const isLoggedIn = useSelector(state => state.Auth.idToken);

  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  useEffect(() => {
    if (isLoggedIn) {
      setRedirectToReferrer(true);
    }
  }, [isLoggedIn]);

  const { from } = location.state || { from: { pathname: '/dashboard' } };

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }
  return (
    <RowWrapper type="flex" justify="center" align="middle">
      <div className="login-content">
        <Row>
          <Col
            xl={{ span: 12 }}
            sm={{ xl: 24 }}
            className="login-content--left"
          >
            <div className="left-container">
              <Row>
                <Col className="mb-4" span={24}>
                  <Title color="white" level={3}>
                    <FormattedMessage id="Hey there!" />
                  </Title>
                </Col>

                <Title color="white" className="font-normal" level={4}>
                  <FormattedMessage id="Here in the customer portal you can find all the information you need for your sailing trip. You have the opportunity to handle payments, read information and supplement information on all travelers." />
                </Title>

                <Title level={4} color="white">
                  Logga in på vår nya kundportal för att hantera bokningar av
                  seglingsresor:
                </Title>
                <Title color="white" className="font-normal" level={4}>
                  Använd ditt nuvarande användarnamn och lösenord för att logga
                  in
                </Title>
                <a
                  href="https://moresailing.customer.bookingsystem.se/"
                  style={{ marginBottom: '7rem', marginTop: '3rem' }}
                >
                  <Button>
                    <Title color="primary" level={3}>
                      Ny kundportal
                      <RightOutlined
                        className="ml-1"
                        style={{ fontSize: '1.9rem' }}
                      />
                    </Title>
                  </Button>
                </a>
              </Row>
              <Row>
                <Col className="mb-1" span={24}>
                  <Row justify="space-between">
                    <Text className color="white">
                      <FormattedMessage id="031 - 30 96 300" />
                    </Text>
                    <Link
                      to={{
                        pathname: 'https://www.moresailing.se/resevillkor/',
                      }}
                      target="_blank"
                    >
                      <Text color="white">
                        *
                        <FormattedMessage id="Travel conditions" />
                      </Text>
                    </Link>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xl={{ span: 12 }} sm={{ xl: 24 }}>
            <Row
              className="login-content--right"
              align="middle"
              justify="center"
            >
              <Col span={24}>
                <LangSwitch className="float-right mb-4" />
              </Col>
              <img className="mb-4" src={msLogo} alt="ms logo" />
              <Col className="mt-4" span={24}>
                <UserAuthForm />
              </Col>
              <div className="new-portal-small-screen">
                <a href="https://moresailing.customer.bookingsystem.se/">
                  <Button>
                    Ny kundportal
                    <RightOutlined
                      className="ml-1"
                      style={{ fontSize: '1rem' }}
                    />
                  </Button>
                </a>
                <p>
                  Logga in på vår nya kundportal för att hantera bokningar av
                  seglingsresor
                </p>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </RowWrapper>
  );
}

export default UserAuth;
