import { Col, Row } from 'antd';
import React, { useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutContentWrapper, Title } from 'styles';
import FlightDetailsDisclaimer from './FlightDetailsDisclaimer';
import FlightList from './FlightList';
import PNRList from './PNRList';
import { getAllFlightTicketsRequest, getAllPNRRequest } from './reducer';

function Flights() {
  const list = useSelector(state => state.FlightTicket.list);
  const pnrList = useSelector(state => state.FlightTicket.pnr);

  const id = useSelector(state => state.User.booking.selected?.id);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllFlightTicketsRequest(id));
    dispatch(getAllPNRRequest(id));
  }, [dispatch, id]);

  return (
    <LayoutContentWrapper>
      {pnrList?.length ? (
        <>
          <Row justify="center">
            <Col span={24}>
              <Title level={3} className="mb-4">
                <FormattedMessage id="Flight Details" />
              </Title>
            </Col>
          </Row>
          <FlightDetailsDisclaimer />
          <PNRList list={pnrList} />
        </>
      ) : null}

      <Row justify="center" className={`${pnrList?.length ? 'mt-4' : ''}`}>
        <Col span={24}>
          <Title level={3} className="mb-4">
            <FormattedMessage id="Flight Tickets" />
          </Title>
        </Col>
      </Row>
      <FlightList list={list} />
    </LayoutContentWrapper>
  );
}

export default Flights;
