import { takeLatest, put, call } from 'redux-saga/effects';
import { FlighTicket } from 'api';
import {
  getAllFlightTickets,
  getAllFlightTicketsRequest,
  getAllPnr,
  getAllPNRRequest,
} from './reducer';

export function* getAllFlightTicketsSaga({ payload }) {
  const { data } = yield call(FlighTicket.index, payload);
  if (data) {
    yield put(getAllFlightTickets(data[0]?.tickets));
  }
}

export function* getAllPnrSaga({ payload }) {
  const { data } = yield call(FlighTicket.pnr, payload);
  if (data) {
    yield put(getAllPnr(data?.response));
  }
}

export default function* watchFlightsActions() {
  yield takeLatest(
    getAllFlightTicketsRequest.toString(),
    getAllFlightTicketsSaga,
  );
  yield takeLatest(getAllPNRRequest.toString(), getAllPnrSaga);
}
