import React, { useState } from 'react';
import { Row, Col, Button, Form, Input, Spin, Result } from 'antd';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { passResetEmailRequest } from 'features/UserAuth';
import getValidateMessages from 'lib/helpers/getValidationMessages';
import { Paragraph } from 'styles';

function ForgotPassword() {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { goBack } = useHistory();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isSpinnerShwoing, setIsSpinnerShowing] = useState(false);

  function onFinish(formData) {
    setIsSpinnerShowing(true);
    dispatch(
      passResetEmailRequest({
        formData,
        setIsEmailSent,
        setIsSpinnerShowing,
      }),
    );
  }

  return (
    <Form
      className="p-4"
      name="user_activation"
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      validateMessages={getValidateMessages(messages)}
    >
      {isSpinnerShwoing && (
        <Row justify="center" className="my-3">
          <Spin size="large" />
        </Row>
      )}
      {!isSpinnerShwoing && !isEmailSent && (
        <>
          <Paragraph className="mt-1 mb-2" level={4}>
            <FormattedMessage id="An email will be sent to this address with a password reset activation link" />
          </Paragraph>
          <Form.Item
            label={messages['Email']}
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
              },
            ]}
            hasFeedback
          >
            <Input placeholder={messages['Email']} size="large" />
          </Form.Item>
        </>
      )}
      {isEmailSent && (
        <Result
          status="success"
          title="An email has been to this address with an activation link!"
        />
      )}
      <Row
        className="mt-3"
        type="flex"
        justify="center"
        align="middle"
        gutter={[20, 20]}
      >
        <Col>
          <Button size="large" onClick={() => goBack()}>
            <FormattedMessage id="Back" />
          </Button>
        </Col>
        {!isSpinnerShwoing && !isEmailSent && (
          <Col>
            <Button size="large" type="primary" htmlType="submit">
              <FormattedMessage id="Submit" />
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
}

export default ForgotPassword;
