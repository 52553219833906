import React, { useEffect } from 'react';
import { Row, Col, Form, Button, InputNumber } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import getValidateMessages from 'lib/helpers/getValidationMessages';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Title } from 'styles';
import { updateAmount } from './reducer';

function SelectAmount({ next }) {
  const { messages } = useIntl();
  const amount = useSelector(state => state.Payment.invoiceForm?.amount);
  const selectedBooking = useSelector(state => state.User.booking.selected);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { due } = selectedBooking;
  const payable = selectedBooking.total_payable;
  const bookingFeeFactor = selectedBooking.booking_fee_percent / 100;
  const paid = payable - due;
  const bookingFee = payable * bookingFeeFactor;
  const bookingFeeDue = bookingFee - paid > 0 ? bookingFee - paid : 0;

  function onFinish(data) {
    if (data?.amount) dispatch(updateAmount(Math.ceil(data?.amount)));
    next();
  }

  const handleUpdateAmount = async value => {
    dispatch(updateAmount(value));
  };

  useEffect(() => {
    if (amount) form.setFieldsValue({ amount });
    else form.setFieldsValue({ amount: undefined });
  }, [form, amount]);

  useEffect(() => {
    if (selectedBooking) {
      dispatch(updateAmount(0));
    }
    // eslint-disable-next-line
  }, [selectedBooking, dispatch]);

  return (
    <Form
      name="selectPayment"
      form={form}
      onFinish={onFinish}
      validateMessages={getValidateMessages(messages, 'name')}
      labelCol={{ span: 24 }}
    >
      <Row justify="center my-4">
        <Col span={24}>
          <Row justify="center" gutter={20} className="py-4">
            {selectedBooking.due >
              (1 - bookingFeeFactor) * selectedBooking.total_payable && (
              <Col className="my-2">
                <Button
                  onClick={() => handleUpdateAmount(Math.ceil(bookingFeeDue))}
                  size="large"
                  className="px-4"
                  type="secondary"
                >
                  <FormattedMessage id="Pay Booking Fee" />
                  <span className="ml-1">
                    {`(${Math.ceil((bookingFeeDue / payable) * 100)}%)`}
                  </span>
                </Button>
              </Col>
            )}
            <Col className="my-2">
              <Button
                onClick={() => handleUpdateAmount(due)}
                size="large"
                className="px-4"
                type="secondary"
              >
                <FormattedMessage id="Pay Remaining Due" />
                <span className="ml-1">
                  {`(${Math.ceil((due / payable) * 100)}%)`}
                </span>
              </Button>
            </Col>
            <Col className="my-2">
              <Button
                onClick={() => handleUpdateAmount(undefined)}
                size="large"
                className="px-4"
                type="secondary"
              >
                <FormattedMessage id="Enter Amount" />
              </Button>
            </Col>
          </Row>
        </Col>
        {amount !== undefined && (
          <Col style={{ margin: '2.21rem 0' }}>
            {amount !== undefined && amount !== 0 && (
              <Title level={2}>
                {`${amount?.toLocaleString('sv-SE')} ${
                  selectedBooking.region.currency
                }`}
              </Title>
            )}
          </Col>
        )}
        {amount === undefined && (
          <Col>
            <Form.Item
              className="mb-1"
              name="amount"
              rules={[
                { required: true },
                () => ({
                  validator(_, value) {
                    if (!value || selectedBooking?.due >= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(messages['Amount can not be greater than due']),
                    );
                  },
                }),
              ]}
              label={`${messages['Choose an amount']} (${selectedBooking?.region?.currency})`}
              hasFeedback
            >
              <InputNumber
                size="large"
                placeholder={`${messages['Choose an amount']} (${selectedBooking?.region?.currency})`}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row className="mt-3 mb-4" type="flex" justify="center">
        {amount !== 0 && (
          <Form.Item>
            <Button
              size="large"
              className="px-4"
              type="primary"
              htmlType="submit"
            >
              {messages.Next}
            </Button>
          </Form.Item>
        )}
      </Row>
    </Form>
  );
}

SelectAmount.propTypes = {
  next: PropTypes.func,
};

export default SelectAmount;
