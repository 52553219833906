import antdEn from 'antd/lib/locale-provider/sv_SE';
import svMessages from '../locales/sv_SE.json';

const SvLang = {
  messages: {
    ...svMessages,
  },
  antd: antdEn,
  locale: 'sv-SE',
};
export default SvLang;
