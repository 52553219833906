import { createAction, createSlice } from '@reduxjs/toolkit';

const initState = {
  list: [],
};

const PassengerSlice = createSlice({
  name: 'Passenger',
  initialState: initState,
  reducers: {
    getAllPassengers(state, { payload }) {
      state.list = payload;
    },
    updatePassportDetails(state, { payload: { index, value } }) {
      state.list[index].passport_number = value;
    },
    updatePassengerAllergie(state, { payload: { index, checked } }) {
      state.list[index].allergie = checked;
    },
  },
});

export const {
  getAllPassengers,
  updatePassportDetails,
  updatePassengerAllergie,
} = PassengerSlice.actions;

export const getAllPassengersRequest = createAction(
  'User/getAllPassengers_request',
);

export const updatePassengersRequest = createAction(
  'User/updatePassengers_request',
);

export const emailBookingInfoRequest = createAction(
  'User/emailBookingInfoRequest',
);

export default PassengerSlice;
