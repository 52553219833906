import React, { useRef } from 'react';
import { Steps } from 'antd';
import { LayoutContentWrapper, LayoutContent, Title } from 'styles';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import SelectAmount from './SelectAmountStep';
import PaymentMethodStep from './PaymentMethodStep';
import { resetPayment, updateStep } from './reducer';
import ConfirmPayment from './ConfirmPayment';

const { Step } = Steps;

function MakePayment() {
  const sectionRef = useRef();
  const currentStep = useSelector(state => state.Payment.step);
  const dispatch = useDispatch();

  function next() {
    dispatch(updateStep(currentStep + 1));
  }

  function prev() {
    dispatch(updateStep(currentStep - 1));
  }

  function handleStepSelection(step) {
    if (currentStep === 1 && step === 0) {
      dispatch(resetPayment());
    }
  }

  const steps = [
    {
      title: <FormattedMessage id="Select amount" />,
      content: <SelectAmount next={next} />,
    },
    {
      title: <FormattedMessage id="Choose payment method" />,
      content: <PaymentMethodStep next={next} prev={prev} />,
    },
    {
      title: <FormattedMessage id="Confirm Payment" />,
      content: <ConfirmPayment prev={prev} sectionRef={sectionRef} />,
    },
  ];

  return (
    <LayoutContentWrapper ref={sectionRef}>
      <LayoutContent className="shadow">
        <Title className="mb-4" level={4}>
          <FormattedMessage id="Make a Payment" />
        </Title>
        <Steps className="mb-4" current={currentStep}>
          {steps.map(item => (
            <Step
              onClick={() => handleStepSelection(steps.indexOf(item))}
              key={item.title}
              title={item.title}
            />
          ))}
        </Steps>
        <div>{steps[currentStep].content}</div>
      </LayoutContent>
    </LayoutContentWrapper>
  );
}

export default MakePayment;
