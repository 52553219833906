import { all } from 'redux-saga/effects';
import { AuthSaga } from 'features/UserAuth';
import { UserSaga } from 'features/Topbar';
import { PaymentSaga } from 'features/Payments';
import { PassengerSaga } from 'features/PassengerDetails';
import { FlightSaga } from 'features/Flights';
import AttachmentsSaga from 'features/Attachments/saga';

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    UserSaga(),
    PaymentSaga(),
    PassengerSaga(),
    FlightSaga(),
    AttachmentsSaga(),
  ]);
}
