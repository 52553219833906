import { Layout } from 'antd';
import styled from 'styled-components';
import { media } from './AppStyles';

export const LayoutContentWrapper = styled.div`
  padding: 20px;
  overflow: hidden;

  @media (max-width: 580px) {
    padding: 15px;
  }
`;

export const LayoutContent = styled.div`
  width: 100%;
  padding: 35px;
  ${media('sm')` padding: 2rem;`}
  border-radius: 1rem;
  height: 100%;
  background-color: #fff;
`;

export const Wrapper = styled.div`
  /* padding: 0; */
  background-color: #fff;
  box-shadow: ${props => props.theme.shadow};
  border-radius: 10px;
  overflow: hidden;
`;

export const PageCenterLayout = styled(Layout)`
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 60rem;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: ${props => props.theme.lightShadow};

    ${media('sm')`
        width: 90%;
        padding: 2rem;
    `};
  }
`;
