import { createAction, createSlice } from '@reduxjs/toolkit';

const initState = {
  list: [],
};

const AttachmentSlice = createSlice({
  name: 'Attachments',
  initialState: initState,
  reducers: {
    getAllAttachments(state, { payload }) {
      state.list = payload;
    },
  },
});

export const { getAllAttachments } = AttachmentSlice.actions;

export const getAllAttachmentsRequest = createAction(
  'User/getAllAttachments_request',
);

export default AttachmentSlice;
