import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutContentWrapper, LayoutContent, Paragraph, Title } from 'styles';
import config from 'config';
import PassengerDetailsForm from './PassengerDetailForm';
import { getAllPassengersRequest } from './reducer';
// import PassengerDetailList from './PassengerDetailList';

function Details() {
  const bookingId = useSelector(state => state.User.booking.selected?.id);
  const tour = useSelector(state => state.User.booking.selected?.tour);
  const departureTime = useSelector(
    state => state.User.booking.selected?.departure_date_time,
  );
  const totalPassenger = useSelector(
    state => state.User.booking.selected?.total_passenger,
  );
  const passengerList = useSelector(state => state.Passenger.list);
  const isUpdatable =
    dayjs(departureTime).diff(dayjs(), 'day') >= config.passengerLockDuration;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPassengersRequest(bookingId));
  }, [dispatch, bookingId]);

  return (
    <>
      <LayoutContentWrapper>
        <LayoutContent className="shadow">
          <Row>
            <Col className="mb-2" span={24}>
              <Title shade={0.1} level={2}>
                {tour}
              </Title>
            </Col>
            <Col span={24}>
              <Title level={3}>
                <FormattedMessage id="Travelers Information" />
                {`: ${passengerList.length}/${totalPassenger} `}
                <FormattedMessage id="persons" />
              </Title>
            </Col>
            <Col className="my-3" span={24}>
              <Paragraph color="grey">
                <FormattedMessage id="Travelers Information subtitle" />
              </Paragraph>
            </Col>
            <PassengerDetailsForm
              totalPassenger={totalPassenger}
              passengerList={passengerList}
              isUpdatable={isUpdatable}
            />
            {/* <PassengerDetailList /> */}
          </Row>
        </LayoutContent>
      </LayoutContentWrapper>
    </>
  );
}

export default Details;
