import { http } from 'services';
import {
  dibsPaymentUrl,
  invoiceUrl,
  dibsPaymentSccsUrl,
  klarnaPaymentUrl,
  klarnaPaySuccCheckUrl,
  trustlyPaymentUrl,
} from './endpoints';

export const Dibs = {
  getPaymentId: data => http.post(dibsPaymentUrl, data),
  confirmSuccess: data => http.post(dibsPaymentSccsUrl, data),
};

export const Klarna = {
  getOrderId: data => http.post(klarnaPaymentUrl, data),
  checkPaymentStatus: ({ orderId, bookingId }) =>
    http.get(
      `${klarnaPaySuccCheckUrl}?order_id=${orderId}&booking_id=${bookingId}`,
    ),
};

export const Trustly = {
  getOrderId: data => http.post(trustlyPaymentUrl, data),
};

export const Invoice = {
  create: data => http.post(invoiceUrl, data),
};
