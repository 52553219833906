import { takeLatest, call, put } from 'redux-saga/effects';
import { Dibs, Booking, Klarna } from 'api';
import { Invoice, Trustly } from 'api/paymentApi';
import { getTransactionListRequest, getTransactionList } from 'features/Topbar';
import {
  getDibsPaymentIdRequest,
  getInvoicePdfRequest,
  getInvoicePdf,
  dibsSuccessPayReq,
  updatePaymentSuccess,
  getKlarnaPaymentIdRequest,
  checkKlarnaPaySuccReq,
  getPaymentSuccessInfo,
  getTrustlyPayemntIdRequest,
} from './reducer';

export function* getDibsPaymentIdSaga({ payload: { reqData, setPaymentId } }) {
  const { data } = yield call(Dibs.getPaymentId, reqData);

  if (data) {
    setPaymentId(data?.paymentId);
  }
}

export function* getKlarnaPaymentIdSaga({ payload: { reqData, setHtmlData } }) {
  const { data } = yield call(Klarna.getOrderId, reqData);

  if (data) {
    setHtmlData(data?.html_snippet);
  }
}

export function* checkKlarnaPaySucc({ payload: { orderId, bookingId, next } }) {
  const { data } = yield call(Klarna.checkPaymentStatus, {
    orderId,
    bookingId,
  });
  if (data?.status === 'checkout_complete') {
    yield put(getPaymentSuccessInfo(data));
    yield put(updatePaymentSuccess(true));
  }
  next();
}

export function* dibsPaymentSuccessSaga({ payload }) {
  const { data } = yield call(Dibs.confirmSuccess, payload);

  if (data) {
    yield put(updatePaymentSuccess(true));
  }
}

export function* getInvoicePdfSaga({ payload: { reqData, prev } }) {
  const { data } = yield call(Invoice.create, reqData);

  if (data?.invoice_pdf_view && data?.invoice_pdf_download) {
    yield put(
      getInvoicePdf({
        view: data.invoice_pdf_view,
        download: data.invoice_pdf_download,
      }),
    );
    yield put(updatePaymentSuccess(true));
  } else {
    prev();
  }
}

export function* getTransactionListSaga({ payload }) {
  const { data } = yield call(Booking.getTransactionList, payload);

  if (data) {
    yield put(getTransactionList(data));
  }
}

export function* getTrustlyPaymentIdSaga({
  payload: { reqData, setCheckoutUrl },
}) {
  const { data } = yield call(Trustly.getOrderId, reqData);

  if (data) {
    setCheckoutUrl(data?.detail?.trustly_iframe_url);
  }
}

export default function* watchPaymentctions() {
  yield takeLatest(getDibsPaymentIdRequest.toString(), getDibsPaymentIdSaga);
  yield takeLatest(
    getKlarnaPaymentIdRequest.toString(),
    getKlarnaPaymentIdSaga,
  );
  yield takeLatest(
    getTrustlyPayemntIdRequest.toString(),
    getTrustlyPaymentIdSaga,
  );
  yield takeLatest(getInvoicePdfRequest.toString(), getInvoicePdfSaga);
  yield takeLatest(dibsSuccessPayReq.toString(), dibsPaymentSuccessSaga);
  yield takeLatest(checkKlarnaPaySuccReq.toString(), checkKlarnaPaySucc);
  yield takeLatest(
    getTransactionListRequest.toString(),
    getTransactionListSaga,
  );
}
