import React, { useEffect } from 'react';
import { Button, Row, Col, Spin, Result } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { DownloadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { PRIVATE_ROUTE } from 'router';
import { getInvoicePdfRequest, resetPayment } from './reducer';

function ConfirmPayment({ prev, sectionRef }) {
  const booking = useSelector(state => state.User.booking.selected?.id);
  const invoiceForm = useSelector(state => state.Payment.invoiceForm);
  const invoicePdfUrl = useSelector(state => state.Payment.invoicePdfUrl);
  const paymentMethod = useSelector(state => state.Payment.paymentMethod);
  const isPaymentSucessful = useSelector(
    state => state.Payment.isPaymentSucessful,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  function handleResetPayment() {
    history.push(`/${PRIVATE_ROUTE.DASHBOARD}/${PRIVATE_ROUTE.PAYMENTS}`);
    dispatch(resetPayment());
  }
  useEffect(() => {
    if (paymentMethod !== 'Invoice') {
      history.push(`/${PRIVATE_ROUTE.DASHBOARD}/${PRIVATE_ROUTE.PAYMENTS}`);
      sectionRef.current.scrollIntoView();
    }
  }, [history, paymentMethod, sectionRef]);

  useEffect(() => {
    if (
      !isPaymentSucessful &&
      paymentMethod === 'Invoice' &&
      !invoicePdfUrl.view
    ) {
      dispatch(
        getInvoicePdfRequest({ reqData: { booking, ...invoiceForm }, prev }),
      );
    }
  }, [
    booking,
    invoiceForm,
    dispatch,
    isPaymentSucessful,
    prev,
    invoicePdfUrl.view,
    paymentMethod,
  ]);

  return (
    <Row justify="center" align="middle">
      <Col className="my-4" span={24} align="middle">
        {isPaymentSucessful && invoicePdfUrl.view && (
          <embed
            frameBorder="0"
            src={invoicePdfUrl.view}
            style={{ width: '90%', height: '70rem', margin: '0 auto' }}
            type="application/pdf"
          />
        )}
        {!isPaymentSucessful && paymentMethod === 'Invoice' && (
          <Spin size="large" />
        )}

        {!isPaymentSucessful &&
          (paymentMethod === 'Card' || paymentMethod === 'Trustly') && (
            <Result
              status="error"
              title={<FormattedMessage id="Payment failed!" />}
            />
          )}
        {isPaymentSucessful && paymentMethod === 'Card' && (
          <Result
            status="success"
            title={<FormattedMessage id="Payment successful!" />}
          />
        )}
        {isPaymentSucessful && paymentMethod === 'Trustly' && (
          <Result
            status="success"
            title={<FormattedMessage id="Payment request has been completed" />}
            subTitle={<FormattedMessage id="TrustlyConfirmSubtext" />}
          />
        )}
      </Col>
      <Row className="mt-3" justify="center">
        <Button size="large" onClick={handleResetPayment} className="m-3">
          <FormattedMessage id="Make Another Payment" />
        </Button>
        {paymentMethod === 'Invoice' && (
          <a href={invoicePdfUrl.download} download>
            <Button
              loading={!invoicePdfUrl.download}
              size="large"
              className="px-4 m-3"
              type="primary"
              icon={<DownloadOutlined />}
            >
              <span>
                <FormattedMessage id="Download Invoice" />
              </span>
            </Button>
          </a>
        )}
      </Row>
    </Row>
  );
}

ConfirmPayment.propTypes = {
  prev: PropTypes.func,
  sectionRef: PropTypes.object,
};

export default ConfirmPayment;
