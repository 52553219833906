import React from 'react';
import { Layout, Row, Col } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { toggleCollapsed, toggleOpenDrawer } from 'store';
import { isScreenSize, Text } from 'styles';
import { FormattedMessage } from 'react-intl';
import TopbarUser from './TopbarUser';
import TopbarWrapper from './styles';
import TopBarLangSwitch from './TopbarLangSwitch';
import TopbarNotification from './TopbarNotification';
import UserBookingSelect from './UserBookingSelect';

const { Header } = Layout;

export default function Topbar() {
  const { collapsed, openDrawer } = useSelector(state => state.App);
  const dispatch = useDispatch();

  const handleSidebarToggle = () => {
    if (isScreenSize('sm')) {
      dispatch(toggleOpenDrawer());
      return;
    }
    dispatch(toggleCollapsed());
  };

  const isCollapsed = collapsed && !openDrawer;

  return (
    <TopbarWrapper>
      <Header
        className={
          isCollapsed ? 'header shadow-long collapsed' : 'header shadow-long'
        }
      >
        <Row
          style={{ height: '100%' }}
          type="flex"
          justify="space-between"
          align="middle"
        >
          <Col>
            <Row align="middle">
              <Col style={{ display: 'flex', alignItems: 'middle' }}>
                {isCollapsed ? (
                  <MenuUnfoldOutlined
                    style={{ fontSize: '2.1rem' }}
                    onClick={handleSidebarToggle}
                  />
                ) : (
                  <MenuFoldOutlined
                    style={{ fontSize: '2.1rem' }}
                    onClick={handleSidebarToggle}
                  />
                )}
              </Col>
              <Col>
                <Text
                  style={{ marginLeft: '1rem', fontSize: '1.65rem' }}
                  color="black"
                >
                  <FormattedMessage id="My Bookings" />
                  <span>:</span>
                </Text>
                <UserBookingSelect />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row
              className="header-right"
              type="flex"
              justify="end"
              align="middle"
              gutter={[{ xl: 20, md: 10, sm: 0 }, 0]}
            >
              {isScreenSize('lg') && (
                <Col align="middle">
                  <TopBarLangSwitch />
                </Col>
              )}
              {!isScreenSize('xxs') && (
                <Col align="middle">
                  <TopbarNotification />
                </Col>
              )}
              {isScreenSize('lg') && (
                <Col align="middle">
                  <TopbarUser />
                </Col>
              )}
              <Col />
            </Row>
          </Col>
        </Row>
      </Header>
    </TopbarWrapper>
  );
}
