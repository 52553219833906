import { combineReducers } from 'redux';
import { AuthSlice } from 'features/UserAuth';
import { PaymentSlice } from 'features/Payments';
import { UserSlice } from 'features/Topbar';
import { PassengerSlice } from 'features/PassengerDetails';
import { FlightTicket } from 'features/Flights';
import Attachment from 'features/Attachments/reducer';
import LangSwitchSlice from './language/reducer';
import AppSlice from './app/reducer';

export default combineReducers({
  Auth: AuthSlice.reducer,
  LanguageSwitcher: LangSwitchSlice.reducer,
  App: AppSlice.reducer,
  Payment: PaymentSlice.reducer,
  User: UserSlice.reducer,
  Passenger: PassengerSlice.reducer,
  FlightTicket: FlightTicket.reducer,
  Attachment: Attachment.reducer,
});
