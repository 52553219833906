import React, { useState } from 'react';
import { Row, Col, Button, Form, Input, Result } from 'antd';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { passResetRequest } from 'features/UserAuth';
import { Link } from 'react-router-dom';
import { PUBLIC_ROUTE } from 'router';
import getValidateMessages from 'lib/helpers/getValidationMessages';

function PasswordResetForm() {
  const dispatch = useDispatch();
  const { token, id } = useParams();
  const { messages } = useIntl();
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  function onFinish(data) {
    dispatch(
      passResetRequest({
        formData: {
          ...data,
          uid: id,
          token,
        },
        successTxt: messages['Password has been succesfully reset!'],
        setIsPasswordReset,
      }),
    );
  }
  return (
    <Form
      className="p-4"
      name="user_activation"
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      validateMessages={getValidateMessages(messages)}
    >
      {isPasswordReset ? (
        <Result
          status="success"
          title="Login with your new updated password!"
        />
      ) : (
        <>
          <Form.Item
            label={messages['Password']}
            name="password"
            rules={[
              {
                required: true,
              },
              {
                min: 6,
              },
            ]}
            hasFeedback
          >
            <Input.Password placeholder={messages['Password']} size="large" />
          </Form.Item>
          <Form.Item
            label={messages['Repeat password']}
            name="confirm_password"
            hasFeedback
            rules={[
              {
                required: true,
              },
              {
                min: 6,
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    messages[
                      'The two passwords that you entered do not match!'
                    ],
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={messages['Repeat password']}
              size="large"
            />
          </Form.Item>
        </>
      )}

      <Row className="mt-3" type="flex" justify="center" align="middle">
        {isPasswordReset ? (
          <Col>
            <Link to={PUBLIC_ROUTE.SIGN_IN}>
              <Button size="large" type="primary">
                <FormattedMessage id="Login" />
              </Button>
            </Link>
          </Col>
        ) : (
          <Col>
            <Button size="large" type="primary" htmlType="submit">
              <FormattedMessage id="Submit" />
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
}

export default PasswordResetForm;
